var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"artist-profile-infos__wrapper"},[(_vm.isLoadingProfilePicture)?_c('Loader',{attrs:{"color":'white'}}):_c('CloudinaryUploadWidget',{attrs:{"uploadOptions":_vm.uploadProfilePictureOptions,"id":"profile-picture"},on:{"upload-success":_vm.handleProfilePictureUpload},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var createAndOpenWidget = ref.createAndOpenWidget;
return [_c('div',{staticClass:"artist-profile-infos__avatar-wrapper",style:({ cursor: 'pointer' }),on:{"click":function () { return _vm.isPublic ? _vm.isLightboxOpen = true : createAndOpenWidget(); }}},[_c('div',{class:{ 'mb-lg px-xs': !_vm.croppedProfilePictureUrl }},[(_vm.croppedProfilePictureUrl)?_c('img',{staticClass:"artist-profile-infos__avatar",attrs:{"src":_vm.croppedProfilePictureUrl,"alt":"Profile picture"}}):_c('Paragraph',{attrs:{"size":'sm',"isTextCentered":""}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.header.addProfilePicture'))+" ")]),(!_vm.isPublic)?_c('Icon',{staticClass:"artist-profile-infos__upload-icon",attrs:{"tag":'button',"size":'xs',"variant":'photo-outline',"type":"button"}}):_vm._e()],1)])]}}])}),_c('div',{staticClass:"artist-profile-infos__details"},[_c('Heading',[_vm._v(" "+_vm._s(_vm.artist.Firstname || 'TODO Firstname')+" ")]),_c('Paragraph',{staticClass:"artist-profile-infos__nickname"},[_vm._v(" @"+_vm._s(_vm.artist.ArtisteNicknameGenerated || "Supername45")+" ")]),_c('Paragraph',{staticClass:"artist-profile-infos__activity",attrs:{"isBold":""}},[_vm._v(" "+_vm._s(_vm.$t(_vm.getActivityTranslation(_vm.artist.ActivityInformations["__typename"])))+" ")]),_c('div',{class:[
        { 'artist-profile-infos__rating--active': _vm.artist.ratings },
        'artist-profile-infos__rating'
      ]},[_c('Icon',{staticClass:"mr-sm",attrs:{"variant":'star-outline'}}),(_vm.artist.ratings)?[_c('span',{staticClass:"mr-xs"},[_vm._v(" 4.3 (link) ")]),_c('span',[_vm._v(" Avis TODO ")])]:_c('Paragraph',{attrs:{"size":'sm'}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.header.rating.noRating'))+" ")])],2),_c('Paragraph',{staticClass:"artist-profile-infos__activity",attrs:{"isBold":""}},[_vm._v(" "+_vm._s(_vm.artist.City)+" ")])],1),(_vm.isLightboxOpen)?_c('Lightbox',{attrs:{"items":[_vm.getCroppedPictureURLWithDimensions({ width: 400, height: 400 })]},on:{"close-lightbox":function($event){_vm.isLightboxOpen = false}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var item = ref.item;
return [_c('img',{attrs:{"src":item,"alt":"Artist picture"}})]}}],null,false,3600139374)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }