import ACTIVITIES_OPTIONS     from './activities.js';


export default {
  [ACTIVITIES_OPTIONS.animator]: [
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Baptême',
    'Bar / Bat Mitsvah',
    'Clubbing',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Afterwork',
    'Rallye',
    'EVJF',
    'EVG',
    'Retraite',
    'Goûter',
    'Kermesse',
    'Jeu',
    'Magie',
    'Cartes',
    'Karaoké',
    'Maquillage',
    'Quizz',
    'Déguisement',
    'BAFA',
    'Maître de cérémonie',
    'Ballon',
    'Jeux gonflable',
    'Chasse aux trésor',
    'Piñata',
    'Atelier créatif',
    'Jeux de société',
    'Pêche aux canards',
    'Chamboule tout',
    'Marionnette',
    'Hypnose',
    'Mentaliste',
    'Mime',
    'Perle',
    'Pâte à sel',
    'Conteur(se)',
    'Gamemaster',
  ],
  [ACTIVITIES_OPTIONS.band]: [
    'Généraliste',
    'Blues',
    'Country',
    'Disco',
    'Funk',
    'Hip - Hop',
    'Jazz',
    'Latino',
    'Lounge',
    'Classique',
    'Musique du monde',
    'Pop',
    'Reggae',
    'RnB',
    'Rock',
    'Soul',
    'Variété française',
    'Rap',
    'Folk',
    'Punk',
    'Métal',
    'Electro',
    'Gospel',
    'Groove',
    'Chambre',
    'Indie',
    'Gypsy',
    'Reggaetton',
    'Salsa',
    'Zouk',
    'Celtique',
    'Bossa Nova',
    'Afro',
    'Samba',
    'Grégorien',
    'Hits actuels',
    'Alternatif',
    'Swing',
    'Flamenco',
    'Tango',
    'Slave',
    'Corse',
    'Brésilien',
    'Cubain',
    'Traditionnel',
    'Israélien',
    'Horrot',
    'Fado',
    'Opéra',
    'Acoustique',
    'Rai',
    'Anglophone',
    'Techno',
    'House',
    'Années 60',
    'Années 70',
    'Années 80',
    'Années 80',
    'Années 90',
    'Années 2000',
    'Moderne',
    'Guitare',
    'Guitare éléctrique',
    'Violon',
    'Harpe',
    'Basse',
    'Violoncelle',
    'Banjo',
    'Ukulélé',
    'Mandoline',
    'Accordéon',
    'Piano',
    'Clavier',
    'Flute',
    'Saxophone',
    'Trompette',
    'Batterie',
    'Percussion',
    'Cérémonie',
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Baptême',
    'Bar / Bat Mitsvah',
    'Clubbing',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Afterwork',
    'Rallye',
    'Garden Party',
    'Retraite',
    'Goûter',
    'Kermesse',
    'Johnny Halliday',
    'Dalida',
    'Claude Françcois',
    'Gims',
    'Bruno Mars',
    'Mickael Jackson',
    'Céline Dion',
    'Elvis Presley',
    'Whitney Houston',
    'Britney Spears',
    'Ed Sheeran',
    'Madonna',
    'Bob Marley',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
  [ACTIVITIES_OPTIONS.circusArtist]: [
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Bar / Bat Mitsvah',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Rallye',
    'EVJF',
    'EVG',
    'Retraite',
    'Goûter',
    'Kermesse',
    'Mime',
    'Magie',
    'Clown',
    'Acrobate',
    'Jongleur',
    'Dresseur',
    'Cracheur de feu',
    'Trapeziste',
    'Funambule',
    'Artiste pyrotechnique',
    'Equilibriste',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
  [ACTIVITIES_OPTIONS.dancer]: [
    'Country',
    'Disco',
    'Funk',
    'Hip - Hop',
    'Latino',
    'Classique',
    'Pop',
    'RnB',
    'Rock',
    'Folk',
    'Groove',
    'Indie',
    'Gypsy',
    'Reggaetton',
    'Salsa',
    'Zouk',
    'Celtique',
    'Bossa Nova',
    'Afro',
    'Samba',
    'Grégorien',
    'Hits actuels',
    'Swing',
    'Flamenco',
    'Tango',
    'Slave',
    'Corse',
    'Brésilien',
    'Cubain',
    'Traditionnel',
    'Israélien',
    'Horrot',
    'Fado',
    'Contemporain',
    'Années 60',
    'Années 70',
    'Années 80',
    'Années 80',
    'Années 90',
    'Années 2000',
    'Indien',
    'Moderne',
    'Cérémonie',
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Baptême',
    'Bar / Bat Mitsvah',
    'Clubbing',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Afterwork',
    'Rallye',
    'EVJF',
    'EVG',
    'Retraite',
    'Goûter',
    'Kermesse',
    'Salon',
    'Couple',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
  [ACTIVITIES_OPTIONS.dj]: [
    'Généraliste',
    'Blues',
    'Country',
    'Disco',
    'Funk',
    'Hip - Hop',
    'Jazz',
    'Latino',
    'Lounge',
    'Classique',
    'Musique du monde',
    'Pop',
    'Reggae',
    'RnB',
    'Rock',
    'Soul',
    'Variété française',
    'Rap',
    'Folk',
    'Punk',
    'Métal',
    'Electro',
    'Gospel',
    'Groove',
    'Indie',
    'Gypsy',
    'Reggaetton',
    'Salsa',
    'Zouk',
    'Celtique',
    'Bossa Nova',
    'Afro',
    'Samba',
    'Grégorien',
    'Hits actuels',
    'Alternatif',
    'Swing',
    'Flamenco',
    'Tango',
    'Slave',
    'Corse',
    'Brésilien',
    'Cubain',
    'Traditionnel',
    'Israélien',
    'Horrot',
    'Fado',
    'Opéra',
    'Acoustique',
    'Rai',
    'Anglophone',
    'Techno',
    'House',
    'Années 60',
    'Années 70',
    'Années 80',
    'Années 80',
    'Années 90',
    'Années 2000',
    'Moderne',
    'Cérémonie',
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Baptême',
    'Bar / Bat Mitsvah',
    'Clubbing',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Afterwork',
    'Rallye',
    'EVJF',
    'EVG',
    'Retraite',
    'Goûter',
    'Kermesse',
    'Garden Party',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
  [ACTIVITIES_OPTIONS.doppleganger]: [
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Baptême',
    'Bar / Bat Mitsvah',
    'Clubbing',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Afterwork',
    'Rallye',
    'EVJF',
    'EVG',
    'Retraite',
    'Goûter',
    'Kermesse',
    'Sosie',
    'Disney',
    'Marvel',
    'Princesse',
    'Fée',
    'Super héros',
    'Clown',
    'Sorcière',
    'Animal',
    'Spider Man',
    'Captain America',
    'Iron man',
    'Batman',
    'Hulk',
    'Dead pool',
    'Cat woman',
    'Wonder woman',
    'Avengers',
    'X - men',
    'Mickey',
    'Minnie',
    'Donald',
    'Ariel',
    'Jasmine',
    'Dingo',
    'Belle',
    'Elsa',
    'Simba',
    'Pluto',
    'Ursula',
    'Winnie',
    'Merida',
    'Stitch',
    'Jafar',
    'Aladin',
    'Prince',
    'Scar',
    'Le roi lion',
    'Tarzan',
    'Aurore',
    'Daisy',
    `Buzz l'éclair`,
    'Esmeralda',
    'Woody',
    'Tigrou',
    'capitaine crochet',
    'La bête',
    'Fée clochette',
    'Reine des Neiges',
    'Bouriquet',
    'Raiponse',
    'Star Wars',
    'Raiponce',
    'Cendrillon',
    'Pocahontas',
    'Baloo',
    'Olaf',
    'Harry potter',
    'Johnny Halliday',
    'Dalida',
    'Claude Françcois',
    'Gims',
    'Bruno Mars',
    'Mickael Jackson',
    'Céline Dion',
    'Elvis Presley',
    'Whitney Houston',
    'Britney Spears',
    'Ed Sheeran',
    'Madonna',
    'Bob Marley',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
  [ACTIVITIES_OPTIONS.hypnotiser]: [
    'Cocktail',
    'Anniversaire',
    'Bar / Bat Mitsvah',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Rallye',
    'EVJF',
    'EVG',
    'Retraite',
    'Animateur',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
  [ACTIVITIES_OPTIONS.magician]: [
    'Close up',
    'Cartes',
    'Enfant',
    'Adolescent',
    'Adulte',
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Bar / Bat Mitsvah',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Afterwork',
    'Rallye',
    'EVJF',
    'EVG',
    'Retraite',
    'Goûter',
    'Kermesse',
    'Garden party',
    'Animal',
  ],
  [ACTIVITIES_OPTIONS.makeupArtist]: [
    'Cérémonie',
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Bar / Bat Mitsvah',
    'Clubbing',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Rallye',
    'EVJF',
    'Goûter',
    'Kermesse',
    'Hénné',
    'Smocky eyes',
    'Contouring',
    'Naturel',
    'Transformation',
    'Prothèse',
    'Star',
    'Animal',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
  [ACTIVITIES_OPTIONS.mentalist]: [
    'Cocktail',
    'Anniversaire',
    'Bar / Bat Mitsvah',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Rallye',
    'EVJF',
    'EVG',
    'Retraite',
    'Animateur',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
  [ACTIVITIES_OPTIONS.musicien]: [
    'Généraliste',
    'Blues',
    'Country',
    'Disco',
    'Funk',
    'Hip - Hop',
    'Jazz',
    'Latino',
    'Lounge',
    'Classique',
    'Musique du monde',
    'Pop',
    'Reggae',
    'RnB',
    'Rock',
    'Soul',
    'Variété française',
    'Rap',
    'Folk',
    'Punk',
    'Métal',
    'Electro',
    'Gospel',
    'Groove',
    'Chambre',
    'Indie',
    'Gypsy',
    'Reggaetton',
    'Salsa',
    'Zouk',
    'Celtique',
    'Bossa Nova',
    'Afro',
    'Samba',
    'Grégorien',
    'Hits actuels',
    'Alternatif',
    'Swing',
    'Flamenco',
    'Tango',
    'Slave',
    'Corse',
    'Brésilien',
    'Cubain',
    'Traditionnel',
    'Israélien',
    'Horrot',
    'Fado',
    'Opéra',
    'Acoustique',
    'Rai',
    'Japonais',
    'Anglophone',
    'Techno',
    'House',
    'Années 60',
    'Années 70',
    'Années 80',
    'Années 80',
    'Années 90',
    'Années 2000',
    'Moderne',
    'Guitare',
    'Guitare éléctrique',
    'Violon',
    'Harpe',
    'Basse',
    'Violoncelle',
    'Banjo',
    'Ukulélé',
    'Mandoline',
    'Accordéon',
    'Piano',
    'Clavier',
    'Flute',
    'Saxophone',
    'Trompette',
    'Batterie',
    'Percussion',
    'Cérémonie',
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Baptême',
    'Bar / Bat Mitsvah',
    'Clubbing',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Afterwork',
    'Rallye',
    'Garden Party',
    'Retraite',
    'Goûter',
    'Kermesse',
    'Johnny Halliday',
    'Dalida',
    'Claude Françcois',
    'Gims',
    'Bruno Mars',
    'Mickael Jackson',
    'Céline Dion',
    'Elvis Presley',
    'Whitney Houston',
    'Britney Spears',
    'Ed Sheeran',
    'Madonna',
    'Bob Marley',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
  [ACTIVITIES_OPTIONS.puppeteer]: [
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Baptême',
    'Bar / Bat Mitsvah',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Rallye',
    'EVJF',
    'EVG',
    'Retraite',
    'Goûter',
    'Kermesse',
    'Théâtre',
    'Animal',
    'Guignol',
    'Disney',
    'Théatre',
    'Ventrioloquie',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
  [ACTIVITIES_OPTIONS.singer]: [
    'Généraliste',
    'Blues',
    'Country',
    'Disco',
    'Funk',
    'Hip - Hop',
    'Jazz',
    'Latino',
    'Lounge',
    'Classique',
    'Musique du monde',
    'Pop',
    'Reggae',
    'RnB',
    'Rock',
    'Soul',
    'Variété française',
    'Rap',
    'Folk',
    'Punk',
    'Métal',
    'Electro',
    'Gospel',
    'Groove',
    'Chambre',
    'Indie',
    'Gypsy',
    'Reggaetton',
    'Salsa',
    'Zouk',
    'Celtique',
    'Bossa Nova',
    'Afro',
    'Samba',
    'Grégorien',
    'Hits actuels',
    'Alternatif',
    'Swing',
    'Flamenco',
    'Tango',
    'Slave',
    'Corse',
    'Brésilien',
    'Cubain',
    'Traditionnel',
    'Israélien',
    'Horrot',
    'Fado',
    'Opéra',
    'Acoustique',
    'Rai',
    'Anglophone',
    'Techno',
    'House',
    'Années 60',
    'Années 70',
    'Années 80',
    'Années 80',
    'Années 90',
    'Années 2000',
    'Moderne',
    'Cérémonie',
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Baptême',
    'Bar / Bat Mitsvah',
    'Clubbing',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Afterwork',
    'Rallye',
    'Retraite',
    'Goûter',
    'Kermesse',
    'Johnny Halliday',
    'Dalida',
    'Claude Françcois',
    'Gims',
    'Bruno Mars',
    'Mickael Jackson',
    'Céline Dion',
    'Elvis Presley',
    'Whitney Houston',
    'Britney Spears',
    'Ed Sheeran',
    'Madonna',
    'Bob Marley',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
  [ACTIVITIES_OPTIONS.singerMusicien]: [
    'Généraliste',
    'Blues',
    'Country',
    'Disco',
    'Funk',
    'Hip - Hop',
    'Jazz',
    'Latino',
    'Lounge',
    'Classique',
    'Musique du monde',
    'Pop',
    'Reggae',
    'RnB',
    'Rock',
    'Soul',
    'Variété française',
    'Rap',
    'Folk',
    'Punk',
    'Métal',
    'Electro',
    'Gospel',
    'Groove',
    'Indie',
    'Gypsy',
    'Reggaetton',
    'Salsa',
    'Zouk',
    'Celtique',
    'Bossa Nova',
    'Afro',
    'Samba',
    'Grégorien',
    'Hits actuels',
    'Alternatif',
    'Swing',
    'Flamenco',
    'Tango',
    'Slave',
    'Corse',
    'Brésilien',
    'Cubain',
    'Traditionnel',
    'Israélien',
    'Horrot',
    'Fado',
    'Opéra',
    'Acoustique',
    'Rai',
    'Anglophone',
    'Techno',
    'House',
    'Années 60',
    'Années 70',
    'Années 80',
    'Années 80',
    'Années 90',
    'Années 2000',
    'Moderne',
    'Guitare',
    'Guitare éléctrique',
    'Violon',
    'Harpe',
    'Basse',
    'Violoncelle',
    'Banjo',
    'Ukulélé',
    'Mandoline',
    'Accordéon',
    'Piano',
    'Clavier',
    'Cérémonie',
    'Cocktail',
    'Anniversaire',
    'Mariage',
    'Baptême',
    'Bar / Bat Mitsvah',
    'Clubbing',
    'Fête',
    'Soirée',
    'Corporate',
    'Entreprise',
    'Privée',
    'Evènement',
    'Afterwork',
    'Rallye',
    'EVJF',
    'EVG',
    'Retraite',
    'Goûter',
    'Kermesse',
    'Johnny Halliday',
    'Dalida',
    'Claude Françcois',
    'Gims',
    'Bruno Mars',
    'Mickael Jackson',
    'Céline Dion',
    'Elvis Presley',
    'Whitney Houston',
    'Britney Spears',
    'Ed Sheeran',
    'Madonna',
    'Bob Marley',
    'Enfant',
    'Adolescent',
    'Adulte',
  ],
};
