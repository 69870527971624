<template>
  <section
    id="progress"
    class="artist-profile-progress__wrapper">
    <header class="artist-profile-progress__header">
      <Heading
        :level="'h4'"
        :tag="'h4'">
        {{ $t('artist.dashboard.menu.profilPublish.title', { percentComplete: progress }) }}
      </Heading>
    </header>
    <TextLink
      v-if="user.IsActive"
      :urlPath="`/artist/profile/public/${user.ID}`"
      class="artist-profile-progress__public-profile">
      {{ $t('artist.dashboard.menu.profilPublish.publicPreview') }}
    </TextLink>
    <Paragraph
      v-if="user.IsActive && !user.IsBanned"
      :size="'sm'"
      class="artist-profile-progress__published-hint">
      <Icon
        :variant="'check-outline'"
        :size="'xs'"
        class="mr-xs">
      </Icon>
      {{ $t('artist.dashboard.menu.profilPublish.profilePublished') }}
    </Paragraph>

    <div
      v-if="!hasCompletedSteps"
      class="artist-profile-progress__progress-list-wrapper">
      <Heading
        :tag="'h4'"
        :level="'h5'"
        class="ml-xxs mb-sm">
        {{ $t('artist.dashboard.menu.profilPublish.todoTitle') }} :
      </Heading>
      <ul class="artist-profile-progress__progress-list">
        <li
          v-for="(progressItem, index) in progressList"
          @click="$emit('progress-item-click', progressItem)"
          :key="`progress-item-${index}`"
          class="artist-profile-progress__progress-item">
          <router-link
            :to="progressItem.link"
            class="artist-profile-progress__progress-link">
            <Icon
              :variant="progressItem.done ? 'check-outline' : 'plus-outline'"
              :color="progressItem.done
                ? 'gradient'
                : progressItem.isMandatory
                  ? 'alert'
                  : 'black'"
              :size="progressItem.done ? 'sm' : 'xxs'"
              :isCircled="!progressItem.done"
              :class="[
                progressItem.done ? 'mr-xs' : 'mr-sm',
                progressItem.done ? 'ml-xxs' : 'ml-xs',
              ]">
            </Icon>
            <Paragraph
              :size="'md'"
              :tag="'span'"
              :color="progressItem.done || !progressItem.isMandatory ? 'black' : 'alert'"
              isBold>
              {{ $t(progressItem.label) }}
            </Paragraph>
          </router-link>
        </li>
      </ul>
    </div>

    <div class="artist-profile-progress__progress-indicator-wrapper">
      <Heading
        :level="'h1'"
        :tag="'span'">
        {{ progress }}
      </Heading>
      <template v-for="(threshold, index) in thresholds">
        <div
          v-if="threshold && (progress >= threshold)"
          :key="`progress-threshold-${index}`"
          :class="[
            'artist-profile-progress__progress-indicator',
            `artist-profile-progress__progress-indicator--${threshold}`,
            ]
          ">
        </div>
      </template>
    </div>
    <Paragraph class="artist-profile-progress__text">
      {{ $t('artist.dashboard.menu.profilPublish.description') }}
    </Paragraph>
    <Paragraph
      v-if="isMandatoryError"
      :color="'alert'"
      class="artist-profile-progress__error-text">
      {{ $t('artist.dashboard.menu.profilPublish.errors.incompleteProfile') }}
    </Paragraph>

    <template v-if="!user.IsBanned">
      <Loader
        v-if="isLoading"
        class="mr-auto mt-xs">
      </Loader>
      <template v-else>
        <Button
          @click="handleToggleProfilePublish"
          class="artist-profile-progress__publish-cta"
          type="button">
          <Icon
            v-if="!user.IsActive"
            :variant="'view-outline'"
            :color="'white'"
            :size="'xs'"
            class="mr-base">
          </Icon>
          {{
            $t(user.IsActive
              ? 'artist.dashboard.menu.profilPublish.unPublish'
              : 'artist.dashboard.menu.profilPublish.cta'
            )
          }}
        </Button>
      </template>
    </template>
  </section>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapActions,
}                     from 'vuex';

import Paragraph      from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button         from '../../../components/atoms/Button/a-Button.vue';
import Loader         from '../../../components/atoms/Loader/a-Loader.vue';
import Icon           from '../../../components/atoms/Icon/a-Icon.vue';
import Heading        from '../../../components/atoms/Heading/a-Heading.vue';
import TextLink       from '../../../components/atoms/TextLink/a-TextLink.vue';


export default {
  name: 'o-ArtistProfileProgress',
  components: {
    Paragraph,
    Button,
    Loader,
    Icon,
    Heading,
    TextLink,
  },
  data: () => ({
    isLoading: false,
    isMandatoryError: false,
    thresholds: [0, 20, 50, 70, 100],
  }),
  computed: {
    ...mapState('User', ['user']),
    ...mapGetters({
      progress: 'User/PROFILE_PROGRESS',
      progressList: 'User/PROFILE_PROGRESS_LIST',
      canPublishProfile: 'User/CAN_PUBLISH_PROFILE',
    }),
    hasCompletedSteps() {
      return this.progressList.every(item => item.done);
    },
  },
  methods: {
    ...mapActions({ toggleArtistPublish: 'User/TOGGLE_PUBLISH_ARTIST' }),
    async handleToggleProfilePublish() {
      if (!this.canPublishProfile) return this.isMandatoryError = true;

      try {
        this.isMandatoryError = false;
        this.isLoading = true;

        await this.toggleArtistPublish();
      } finally {
        this.isLoading = false;
      }
    },
  },
}

</script>

<style lang="scss">

.artist-profile-progress {
  &__wrapper {
    display: grid;
    grid-template-columns: 100px auto auto;
    grid-template-rows: auto 1fr;
    grid-gap: var(--space-md) var(--space-base);
    padding: var(--space-lg);
    background-color: var(--color-white);
    border-radius: var(--rounded-xl);
    font-family: var(--font-stack-secondary);
  }

  &__header {
    grid-row: 1 / 2;
    grid-column: 2 / -1;
  }

  &__progress-indicator-wrapper {
    position: relative;
    grid-row: 1 / 3;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    border-radius: var(--rounded-circle);
    border: 1px solid var(--color-grey-neutral);
  }

  &__progress-indicator {
    position: absolute;
    height: 50%;
    width: 50%;
    border: 2px solid var(--color-black);

    &--20 {
      top: 0;
      right: 0;
      border-left: none;
      border-bottom: none;
      border-radius: 0 100% 0 0;
    }

    &--50 {
      bottom: 0;
      right: 0;
      border-top: none;
      border-left: none;
      border-radius: 0 0 100% 0;
    }

    &--70 {
      bottom: 0;
      left: 0;
      border-top: none;
      border-right: none;
      border-radius: 0 0 0 100%;
    }

    &--100 {
      top: 0;
      left: 0;
      border-bottom: none;
      border-right: none;
      border-radius: 100% 0 0 0;
    }
  }

  &__text {
    grid-row: 2 / 3;
    grid-column: 2 / -1;
    margin-top: calc(-1 * var(--space-base));
  }

  &__publish-cta {
    grid-column: 1 / -1;
    margin-top: var(--space-xs);
  }

  &__public-profile {
    grid-row: 3 / 4;
    grid-column: 2 / 4;
  }

  &__published-hint {
    display: flex;
    align-items: center;
    align-self: end;
  }

  &__progress-list-heading {
    grid-row: 3 / 4;
  }

  &__hint,
  &__progress-list-wrapper {
    grid-column: 1 / -1;
  }

  &__progress-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: var(--space-sm);
    }
  }

  &__progress-link {
    display: flex;
    align-items: center;
  }

  &__error-text {
    grid-column: 1 / -1;
    margin-top: var(--space-xxs);
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xxs);
    font-weight: 600;
    line-height: var(--lineheight-xs);
  }
}

</style>