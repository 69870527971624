<template>
  <section :class="[
    'artist-profile-performance__wrapper',
    { 'artist-profile-performance__wrapper--public': isPublic }
  ]"
  ref="wrapper">
    <ArtistProfileEditPerformance
      v-if="isEditingPerformance"
      @success="isEditingPerformance = false"
      :artist="artist"
      class="mb-lg"
      id="edit-performance">
    </ArtistProfileEditPerformance>
    <template v-else>
      <ul
        @click="isPublic ? false : isEditingPerformance = true"
        class="artist-profile-performance__infos-list">
        <li class="
          artist-profile-performance__infos-item
          artist-profile-performance__infos-item--description
        ">
          <Paragraph :size="artist.Description ? 'base' : 'sm'">
            {{ artist.Description || $t('artist.dashboard.menu.header.editPerformance.description.notSet') }}
          </Paragraph>
        </li>
        <li class="artist-profile-performance__infos-item">
          <Icon
            :variant="'path-outline'"
            :size="'sm'"
            :color="artist.LocationRange ? 'gradient' : 'black'"
            class="mr-base">
          </Icon>
          <Paragraph v-if="artist.LocationRange">
            <i18n
              path="artist.dashboard.menu.header.editPerformance.locationRange.performanceLocation"
              tag="span">
              <template #distance>
                <b class="artist-profile-performance__info-bold">{{ artist.LocationRange }} km</b>
              </template>
              <template #city>
                {{ artist.City }}
              </template>
            </i18n>
          </Paragraph>
          <Paragraph
            v-else
            :size="'sm'">
            {{ $t('artist.dashboard.menu.header.editPerformance.locationRange.notSet') }}
          </Paragraph>
        </li>
        <li class="artist-profile-performance__infos-item">
          <Icon
            :variant="'time-outline'"
            :color="artist.PerformanceDuration ? 'gradient' : 'black'"
            :size="'sm'"
            class="mr-base">
          </Icon>
          <Paragraph v-if="artist.PerformanceDuration">
            <b class="artist-profile-performance__info-bold">
              {{ artist.PerformanceDuration }}{{ artist.performanceDuration === 30 ? 'mn' : 'h' }}
            </b>
            de prestation
          </Paragraph>
          <Paragraph
            v-else
            :size="'sm'">
            {{ $t('artist.dashboard.menu.header.editPerformance.duration.notSet') }}
          </Paragraph>
        </li>
        <li
          v-if="artist.ActivityInformations && (artist.ActivityInformations.NumberOfMembers !== undefined)"
          class="artist-profile-performance__infos-item">
          <Icon
            :variant="'user-outline'"
            :color="artist.ActivityInformations.NumberOfMembers ? 'gradient' : 'black'"
            :size="'sm'"
            class="mr-base">
          </Icon>
          <Paragraph v-if="artist.ActivityInformations.NumberOfMembers">
            <b class="artist-profile-performance__info-bold">
              {{ artist.ActivityInformations.NumberOfMembers }}
            </b>
            {{ $t('common.members') }}
          </Paragraph>
          <Paragraph
            v-else
            :size="'sm'">
            {{ $t('artist.dashboard.menu.header.editPerformance.bandMember.title') }}
          </Paragraph>
        </li>
        <li
          v-if="artist.ActivityInformations && (artist.ActivityInformations.NumberOfClientSupported !== undefined)"
          class="artist-profile-performance__infos-item">
          <Icon
            :variant="'user-outline'"
            :color="artist.ActivityInformations.NumberOfClientSupported ? 'gradient' : 'black'"
            :size="'sm'"
            class="mr-base">
          </Icon>
          <Paragraph v-if="artist.ActivityInformations.NumberOfClientSupported">
            <b class="artist-profile-performance__info-bold">
              {{ artist.ActivityInformations.NumberOfClientSupported }}
            </b>
            {{ $t('artist.dashboard.menu.header.editPerformance.performanceDetails.participants') }}
          </Paragraph>
        </li>
        <li
          v-if="artist.ActivityInformations && (artist.ActivityInformations.NumberOfTricks !== undefined)"
          class="artist-profile-performance__infos-item">
          <Icon
            :variant="'profession-outline'"
            :color="artist.ActivityInformations.NumberOfTricks ? 'gradient' : 'black'"
            :size="'sm'"
            class="mr-base">
          </Icon>
          <Paragraph v-if="artist.ActivityInformations.NumberOfTricks">
            <b class="artist-profile-performance__info-bold">
              {{ artist.ActivityInformations.NumberOfTricks }}
            </b>
            {{ $t('artist.dashboard.menu.header.editPerformance.performanceDetails.tricks') }}
          </Paragraph>
        </li>
        <li
          v-if="artist.ActivityInformations && (artist.ActivityInformations.NumberOfCostumes !== undefined)"
          class="artist-profile-performance__infos-item">
          <Icon
            :variant="'user-outline'"
            :color="artist.ActivityInformations.NumberOfCostumes ? 'gradient' : 'black'"
            :size="'sm'"
            class="mr-base">
          </Icon>
          <Paragraph v-if="artist.ActivityInformations.NumberOfCostumes">
            <b class="artist-profile-performance__info-bold">
              {{ artist.ActivityInformations.NumberOfCostumes }}
            </b>
            {{ $t('artist.dashboard.menu.header.editPerformance.performanceDetails.costumes') }}
          </Paragraph>
        </li>
        <li
          class="
            artist-profile-performance__infos-item
            artist-profile-performance__infos-item--tags
          ">
          <ul
            v-if="artist.Tags && artist.Tags.length"
            class="artist-profile-performance__tags-list">
            <li
              v-for="(tag, index) in artist.Tags"
              :key="`tag-${index}`"
              class="artist-profile-performance__tags-item">
              <Tag :color="'neutral'">
                {{ tag }}
              </Tag>
            </li>
          </ul>
          <Tag
            v-else
            :color="'neutral'">
            {{ $t('artist.dashboard.menu.header.editPerformance.tagsArtists.notSet') }}
          </Tag>
        </li>
        <li
          v-if="artistLanguages"
          class="
            artist-profile-performance__infos-item
            artist-profile-performance__infos-item--tags
          ">
          <ul class="artist-profile-performance__tags-list">
            <li
              v-for="(language, index) in artistLanguages"
              :key="`language-${index}`"
              class="artist-profile-performance__tags-item">
              <Tag :color="'neutral'">
                {{ $t(language) }}
              </Tag>
            </li>
          </ul>
        </li>
      </ul>

      <Icon
        v-if="!isPublic"
        @click="isEditingPerformance = true"
        :variant="'edit-outline'"
        :tag="'button'"
        :size="'xs'"
        :color="'white'"
        :class="$mediaQueries.isDesktop ?  'ml-lg' : 'ml-sm'"
        :backdropColor="'black'"
        type="button"
        id="edit-performance">
      </Icon>
    </template>
  </section>
</template>

<script>

import { mapState }                           from 'vuex';

import Paragraph                              from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Icon                                   from '../../../components/atoms/Icon/a-Icon.vue';
import Tag                                    from '../../../components/atoms/Tag/a-Tag.vue';
import ArtistProfileEditPerformance           from './o-ArtistProfileEditPerformance.vue';
import LANGUAGES_OPTIONS                      from '../../../constants/languages.js';
import { sortTranslationsAlphabetically }     from '../../../utils/i18nUtils';


export default {
  name: 'o-ArtistProfilePerformance',
  components: {
    Paragraph,
    Icon,
    Tag,
    ArtistProfileEditPerformance,
  },
  props: {
    artist: {
      type: Object,
      required: true
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    languagesOptions: LANGUAGES_OPTIONS,
    isEditingPerformance: false,
  }),
  computed: {
    ...mapState('User', ['user']),
    artistLanguages() {
      return this.artist?.Languages
        ?.map(language => this.$t(this.languagesOptions.find(({ value }) => value === language).label))
        ?.sort(sortTranslationsAlphabetically)
        ?? [];
    },
  },
  watch: {
    isEditingPerformance(isEditing, wasEditing) {
      if (wasEditing && !isEditing) this.$refs.wrapper.scrollIntoView();
    },
  },
  methods: {
    getInformationVisibility(prop) {
      return this.artist.ActivityInformations[prop] !== undefined;
    },
  }
}

</script>

<style lang="scss">

.artist-profile-performance {
  &__wrapper {
    grid-row: 4 / 5;
    display: flex;
    padding: var(--space-lg) 0;
    padding-bottom: var(--space-base);
    border-top: 1px solid var(--color-grey-semi);
    border-bottom: 1px solid var(--color-grey-semi);

    @media screen and ($desktop) {
      grid-row: 3 / 4;
      margin-bottom: var(--space-base);
    }

    &--public {
      padding: 0;
      margin-bottom: 0;
      border: none;
    }
  }

  &__infos-list {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--space-lg);
    flex: 1 1 auto;

    @media screen and ($desktop) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__infos-item {
    display: flex;
    align-items: center;

    &--description,
    &--tags {
      grid-column: 1 / -1;
    }

    &--tags {
      &:last-child {
        margin-top: calc(-1 * var(--space-lg));
      }
    }
  }

  &__tags-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__tags-item {
    margin-bottom: var(--space-base);

    &:not(:last-child) {
      margin-right: var(--space-sm);
    }
  }

  &__info-bold {
    font-weight: var(--font-bold);
  }
}

</style>
