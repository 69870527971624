<template>
  <div class="agenda-calendar-availability__wrapper">
    <button
      v-on="$listeners"
      :class="[dayStyle, 'agenda-calendar-availability__day']"
      type="button">
      {{ day.day }}
    </button>
  </div>
</template>

<script>

export default {
  name: 'o-AgendaCalendarAvailability',
  props: {
    day: {
      type: Object
    },
    dayEvents: {
      type: Object
    },
    attributes: {
      type: Array
    },
    attributesMap: {
      type: Object
    },
    isClient: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    isPastDate() {
      return !this.day.isToday && (this.day.dateTime < Date.parse(new Date));
    },
    isUnavailable() {
      return ['booked', 'unavailable'].includes(this.attributes?.[0]?.key);
    },
    dayStyle() {
      const attributes = this.attributes?.length
        ? this.attributes[this.attributes.length - 1]
        : {};

      return {
        'agenda-calendar-availability__day--normal': !this.isPastDate && attributes.key?.includes('normal'),
        'agenda-calendar-availability__day--exception': !this.isPastDate && attributes.key?.includes('exception'),
        'agenda-calendar-availability__day--unavailable': !this.isPastDate && attributes.key?.includes('unavailable'),
        'agenda-calendar-availability__day--booked': !this.isClient && !this.isPastDate && attributes.key?.includes('booked'),
        'agenda-calendar-availability__day--today': this.day.isToday,
        'agenda-calendar-availability__day--client-selected': attributes.key?.includes('client-selected'),
        'agenda-calendar-availability__day--today-client-selected': attributes.key?.includes('client-selected'),
        'agenda-calendar-availability__day--today-client': this.day.isToday && this.isClient,
        'agenda-calendar-availability__day--client': this.isClient && !this.isPastDate && !this.isUnavailable,
        'agenda-calendar-availability__day--booked-or-unavailable-client': this.isClient && !this.isPastDate && (attributes.key?.includes('booked') || attributes.key?.includes('unavailable')),
        'agenda-calendar-availability__day--past': !this.day.isToday && this.isPastDate,
      }
    },
  }
}

</script>

<style lang="scss">

.agenda-calendar-availability {
  &__wrapper {
    --dayDimension: 44px;

    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--dayDimension);
    width: var(--dayDimension);
    margin: var(--space-xxs) auto;
  }

  &__day {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--dayDimension);
    width: var(--dayDimension);
    font-size: var(--text-sm);
    font-weight: var(--font-medium);
    line-height: var(--lineheight-md);
    color: var(--color-black);
    border-radius: var(--rounded-circle);
    user-select: none;

    &--past {
      color: var(--color-grey-empty);
      background: transparent;
      cursor: default !important;
    }

    &--today {
      &:after {
        content: "";
        position: absolute;
        top: 4px;
        height: 5px;
        width: 5px;
        border-radius: var(--rounded-circle);
        background: var(--color-grey-dark);
      }

      &-client {
        font-weight: var(--font-ultra) !important;
        color: var(--color-black);
      }

      &-client-selected {
        &:after {
          background: var(--color-white);
        }
      }
    }

    $types: (
      "normal": "base",
      "weekend": "weekend",
      "exception": "exceptional",
      "unavailable": "unavailable",
    );

    @each $type-key, $type-value in $types {
      &--#{$type-key} {
        font-weight: var(--font-medium);
        color: var(--color-black);
        border: 2px solid var(--color-pricing-#{$type-value});
        background: var(--color-pricing-#{$type-value});

        @if $type-key == "weekend" or $type-key == "exception" {
          color: var(--color-white);
        }
        @if $type-key == "normal" {
          border: 1px solid var(--color-grey-background);
        }
      }

      &--#{$type-key}-booked {
        font-weight: var(--font-ultra);
        color: var(--color-white);
        border: 2px solid var(--color-pricing-#{$type-value});
        background: var(--color-pricing-#{$type-value});
      }

      &--#{$type-key}.agenda-calendar-availability__day--today {
        &:after {
          @if $type-key == 'normal' or $type-key == 'unavailable' {
            background: var(--color-black);
          } @else {
            background: var(--color-white);
          }
        }
      }
    }

    &--client {
      color: var(--color-black);
      border: 2px solid var(--color-white);
      background: var(--color-white);

      &:hover {
        color: var(--color-white);
        background: var(--color-gradient-main);
      }
    }

    &--client-selected {
      color: var(--color-white);
      font-weight: var(--font-semi);
      border: 2px solid var(--color-white);
      background: var(--color-gradient-main);
    }

    &--booked-or-unavailable-client {
      color: var(--color-grey-semi);
      text-decoration: line-through;
      background: transparent;
      cursor: default !important;
    }

    &--booked {
      color: var(--color-white);
      border: 2px solid var(--color-black);
      background: var(--color-black);
    }
  }
}
</style>