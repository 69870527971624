<template>
  <section :class="[
    'artist-profile-header__wrapper',
    { 'artist-profile-header__wrapper--artist': !isPublic }
  ]">
    <div
      v-if="isPreview"
      class="artist-profile-header__public-wrapper">
      <router-link :to="{ name: 'ArtistProfile' }">
        <Icon
          :variant="'arrow-left-outline'"
          :size="$mediaQueries.isDesktop ? 'sm' : 'xs'">
        </Icon>
      </router-link>
      <Heading
        :tag="'h4'"
        :level="'h4'"
        class="artist-profile-header__public-heading">
        {{ $t('artist.dashboard.menu.profilPublish.publicPreview') }}
      </Heading>
    </div>

    <router-link
      v-else-if="isPublic"
      :to="{ name: 'ClientDashboard' }"
      class="artist-profile-header__public-back-button">
      <Icon
        :variant="'arrow-left-outline'"
        :size="'xs'"
        :backdropColor="'white'">
      </Icon>
    </router-link>

    <template v-else>
      <AppBreadcrumb
        v-if="$mediaQueries.isDesktop"
        :levels="[
          { label: $t('common.dashboard'), link: 'ArtistDashboard' },
          { label: $t('common.profile') }
        ]">
      </AppBreadcrumb>
      <router-link
        :to="'#artist-agenda'"
        class="artist-profile-header__agenda">
        <Button
          :tag="'span'"
          isWhite
          isTransparentBackground
          type="button"
          style="webkit-appearance: none !important;">
          {{ $t('artist.dashboard.menu.header.ctaAgenda') }}
        </Button>
      </router-link>
    </template>

    <ArtistProfileCover :isPublic="isPublic"></ArtistProfileCover>
  </section>
</template>

<script>

import Button                 from '../../../components/atoms/Button/a-Button.vue';
import Heading                from '../../../components/atoms/Heading/a-Heading.vue';
import Icon                   from '../../../components/atoms/Icon/a-Icon.vue';
import AppBreadcrumb          from '../../../components/molecules/AppBreadcrumb/m-AppBreadcrumb.vue';
import ArtistProfileCover     from './o-ArtistProfileCover.vue';


export default {
  name: 'o-ArtistProfileHeader',
  components: {
    Button,
    Heading,
    Icon,
    AppBreadcrumb,
    ArtistProfileCover,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false
    },
    isPreview: {
      type: Boolean,
      required: false,
    },
  },
}

</script>

<style lang="scss">

.artist-profile-header {
  &__wrapper {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    grid-column: 1 / -1;
    margin: 0 calc(-1 * var(--space-base));
    overflow: hidden;

    @media screen and ($desktop) {
      margin: 0;
    }

    &--artist {
      grid-template-rows: 87px 1fr;
    }
  }

  &__agenda {
    grid-column: 2 / 3;
    justify-self: center;

    @media screen and ($desktop) {
      justify-self: end;
    }
  }

  &__public-wrapper {
    grid-column: 1 / -1;
    display: flex;
    align-items: center;
    padding: var(--space-md) var(--space-base);

    a {
      grid-column: 1 / 2;
      display: flex;
    }

    @media screen and ($desktop) {
      padding: var(--space-lg) 0;
    }
  }

  &__public-heading {
    font-weight: var(--font-light);
    margin: 0 auto;
  }

  &__public-back-button {
    position: absolute;
    top: var(--space-lg);
    left: var(--space-lg);
  }
}

</style>
