export default [
  {
    activity: "Singer",
    questions: [
      'faqList.songNumber',
      'faqList.chooseSong',
      'faqList.learnSong',
      'faqList.playInstrument',
      'faqList.setupTime',
      'faqList.performanceSpace',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.equipementSpecificity',
      'faqList.performanceOutfit',
      'faqList.other',
    ],
  },
  {
    activity: "Musicien",
    questions: [
      'faqList.songNumber',
      'faqList.chooseSong',
      'faqList.learnSong',
      'faqList.playInstrument',
      'faqList.setupTime',
      'faqList.performanceSpace',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.equipementSpecificity',
      'faqList.performanceOutfit',
      'faqList.other',
    ],
  },
  {
    activity: "SingerMusicien",
    questions: [
      'faqList.songNumber',
      'faqList.chooseSong',
      'faqList.learnSong',
      'faqList.playInstrument',
      'faqList.setupTime',
      'faqList.performanceSpace',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.equipementSpecificity',
      'faqList.performanceOutfit',
      'faqList.other',
    ],
  },
  {
    activity: "Band",
    questions: [
      'faqList.bandComposition',
      'faqList.songNumber',
      'faqList.chooseSong',
      'faqList.learnSong',
      'faqList.setupTime',
      'faqList.performanceSpace',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.equipementSpecificity',
      'faqList.performanceOutfit',
      'faqList.other',
    ],
  },
  {
    activity: "DJ",
    questions: [
      'faqList.djSet',
      'faqList.playInstrument',
      'faqList.setupTime',
      'faqList.performanceSpace',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.equipementSpecificity',
      'faqList.performanceOutfit',
      'faqList.other',
    ],
  },
  {
    activity: "Animator",
    questions: [
      'faqList.activityExamples',
      'faqList.setupTime',
      'faqList.performanceSpace',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.equipementSpecificity',
      'faqList.performanceOutfit',
      'faqList.other',
    ],
  },
  {
    activity: "Dancer",
    questions: [
      'faqList.chooseDanceSong',
      'faqList.customChoreography',
      'faqList.performanceSpace',
      'faqList.setupTime',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.equipementSpecificity',
      'faqList.performanceOutfit',
      'faqList.other',
    ],
  },
  {
    activity: "CircusArtist",
    questions: [
      'faqList.listPerformance',
      'faqList.customPerformance',
      'faqList.setupTime',
      'faqList.performanceSpace',
      'faqList.equipementSpecificity',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.performanceOutfit',
      'faqList.other',
    ],
  },
  {
    activity: "Doppleganger",
    questions: [
      'faqList.charactersList',
      'faqList.existingPerformance',
      'faqList.setupTime',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.performanceSpace',
      'faqList.equipementSpecificity',
      'faqList.outfitNumber',
      'faqList.other',
    ],
  },
  {
    activity: "MakeupArtist",
    questions: [
      'faqList.executableTechniques',
      'faqList.customeMakeupTheme',
      'faqList.henna',
      'faqList.setupTime',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.performanceSpace',
      'faqList.equipementSpecificity',
      'faqList.other',
    ],
  },
  {
    activity: "Puppeteer",
    questions: [
      'faqList.customPerformance',
      'faqList.charactersStories',
      'faqList.setupTime',
      'faqList.performanceSpace',
      'faqList.equipementSpecificity',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.other',
    ],
  },
  {
    activity: "Magician",
    questions: [
      'faqList.chooseTricks',
      'faqList.setupTime',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.performanceSpace',
      'faqList.equipementSpecificity',
      'faqList.other',
    ],
  },
  {
    activity: "Mentalist",
    questions: [
      'faqList.setupTime',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.performanceSpace',
      'faqList.equipementSpecificity',
      'faqList.other',
    ],
  },
  {
    activity: "Hypnotiser",
    questions: [
      'faqList.setupTime',
      'faqList.communicationMeans',
      'faqList.equipementDetails',
      'faqList.performanceSpace',
      'faqList.equipementSpecificity',
      'faqList.other',
    ],
  }
];
