<template>
  <figure
    @mouseenter="localIsShowControls = true"
    @mouseleave="localIsShowControls = Boolean(videoElement && videoElement.paused)"
    class="video-media-card__wrapper">
    <div
      class="video-media-card__video-wrapper"
      ref="video-wrapper">
      <video
        v-bind="$attrs"
        v-if="source"
        @dblclick="handleRequestFullScreen"
        @click="handleToggleVideoPlayState"
        @timeupdate="handleCurrentTimeUpdate"
        @durationchange="handleDurationChange"
        @loadedmetadata="handleMetadataLoad"
        @error="handleError"
        @loadstart="isBuffering = true"
        @stalled="isBuffering = true"
        @waiting="isBuffering = true"
        @playing="isBuffering = false"
        @canplay="isBuffering = false"
        @play="isPlayingVideo = true"
        @ended="pauseVideo"
        :src="source"
        :class="[videoMediaStyle, 'video-media-card__video']"
        preload="metadata"
        ref="video">
      </video>

      <transition name="fade">
        <Icon
          v-if="!isPlayingVideo && !isLoading && videoDuration"
          @click="handleToggleVideoPlayState"
          :variant="isPlayingVideo ? 'pause-solid' : 'play-outline'"
          :size="'xl'"
          :tag="'button'"
          :color="'white'"
          type="button"
          class="video-media-card__play-cta">
        </Icon>
      </transition>

      <transition name="fade">
        <div
          v-if="shouldDisplayControls"
          class="video-media-card__controls">
          <div
            ref="progress"
            class="video-media-card__progress-wrapper">
            <span class="
              video-media-card__progress-time
              video-media-card__progress-time--start
            ">
              {{ formattedStartTime }}
            </span>
            <progress
              @click="handleProgressBarClick"
              :value="currentVideoPercentageProgress"
              :min="0"
              :max="100"
              ref="progress"
              class="video-media-card__progress">
            </progress>
            <span class="
              video-media-card__progress-time
              video-media-card__progress-time--end
            ">
              {{ formattedEndTime }}
            </span>
          </div>
          <Icon
            @click="handleToggleVideoMutedState"
            :variant="isVideoMuted ? 'muted-solid' : 'sound-solid'"
            :size="'xs'"
            :tag="'button'"
            :color="'white'"
            type="button"
            class="ml-sm">
          </Icon>
        </div>
      </transition>
    </div>

    <div
      v-if="isError || isLoading || isBuffering || isPending"
      class="video-media-card__infos">
      <Loader
        v-if="isLoading || isBuffering || isPending"
        :color="'white'"
        class="mb-sm">
      </Loader>
      <span
        v-if="isPending"
        class="video-media-card__error">
        {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.pendingVideo') }}
      </span>
      <span
        v-else-if="!isLoading && isError"
        class="video-media-card__error">
        {{ $t('common.errors.UnknownError') }}
      </span>
    </div>

    <div
      v-if="isShowTrimmer"
      class="m-xl">
      <Vtrim
        @trim-start="handleTrimStart"
        @trim-end="handleTrimEnd"
        @seek="handleSeek"
        @play-overflow="handlePlayOverflow"
        :currentTime="currentTime"
        :mediaDuration="videoDuration"
        :defaultTrim="existingTrim
          ? {
              start: existingTrim.so || 0,
              end: existingTrim.eo || videoDuration,
            }
          : null"
        restrictSeeking>
        <template #start-time="{ startTime: start }">
          <Paragraph
            :tag="'span'"
            :size="'sm'"
            class="video-media-card__trim-progress-time">
            {{ start }}
          </Paragraph>
        </template>
        <template #start-trim>
          <button
            type="button"
            class="video-media-card__trim-handle">
          </button>
        </template>
        <template #end-trim>
          <button
            type="button"
            class="video-media-card__trim-handle">
          </button>
        </template>
        <template #end-time="{ endTime: end }">
          <Paragraph
            :tag="'span'"
            :size="'sm'"
            class="video-media-card__trim-progress-time">
            {{ end }}
          </Paragraph>
        </template>
        <template #playhead>
          <div class="video-media-card__trim-playhead-wrapper">
            <Paragraph
              :tag="'span'"
              isBold>
              {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.thumbnail') }}
            </Paragraph>
            <div class="video-media-card__trim-playhead"></div>
          </div>
        </template>
        <template #playing-time>
          <span></span>
        </template>
        <template #progress="{ currentRightPosition }">
          <div class="video-media-card__trim-progress"></div>
          <div
            :style="{ right: `${currentRightPosition}%` }"
            class="video-media-card__trim-progress--playing">
          </div>
        </template>
        <template #timeline="{ startLeftPosition, endRightPosition }">
          <div
            :style="{ left: `${startLeftPosition}%`, right: `${endRightPosition}%` }"
            class="video-media-card__trim-timeline">
          </div>
        </template>
        <template #timeline-playing="{ currentRightPosition }">
          <div
            :style="{ left: 0, right: `${currentRightPosition}%` }"
            class="video-media-card__trim-timeline-playing">
          </div>
        </template>
      </Vtrim>
    </div>

    <div
      v-if="shouldDisplayControls"
      class="video-media-card__actions-wrapper">
      <slot name="actions"></slot>
    </div>
  </figure>
</template>

<script>

import { Vtrim }              from 'vtrim';

import Loader                 from '../../atoms/Loader/a-Loader.vue';
import Icon                   from '../../atoms/Icon/a-Icon.vue';
import Paragraph              from '../../atoms/Paragraph/a-Paragraph.vue';
import { getTimeAsMMSS }      from '../../../utils/dateUtils.js';


export default {
  name: 'm-VideoMediaCard',
  inheritAttrs: false,
  components: {
    Loader,
    Icon,
    Paragraph,
    Vtrim,
  },
  props: {
    source: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isPending: {
      type: Boolean,
      default: false,
    },
    isShowTrimmer: {
      type: Boolean,
      default: false,
    },
    isRoundedBorders: {
      type: Boolean,
      default: true,
    },
    isShowControls: {
      type: Boolean,
      default: false,
    },
    existingTrim: {
      type: Object,
      default: null
    },
    maxVideoHeight: {
      type: String,
      default: '400px',
    },
  },
  data: () => ({
    videoDuration: 0,
    videoElement: null,
    isPlayingVideo: false,
    currentTime: 0,
    startTime: 0,
    endTime: 0,
    localIsShowControls: true,
    isBuffering: false,
    isVideoMuted: false,
    isError: false,
    observer: null,
  }),
  computed: {
    formattedStartTime() {
      return Number(this.startTime) >= 0 ? this.getTimeAsMMSS(this.startTime) : '-- : --';
    },
    formattedEndTime() {
      return Number(this.endTime) >= 0 ? this.getTimeAsMMSS(this.endTime) : '-- : --';
    },
    currentVideoPercentageProgress() {
      return Math.round((this.currentTime / this.videoDuration) * 100);
    },
    videoMediaStyle() {
      return { 'video-media-card__video--rounded-borders': this.isRoundedBorders, };
    },
    shouldDisplayControls() {
      return Boolean(this.isShowControls && this.localIsShowControls && !this.isLoading && !this.isPending && this.source);
    }
  },
  mounted() {
    this.videoElement = this.$refs.video;
    this.$refs['video-wrapper'].style.setProperty('--maxHeight', this.maxVideoHeight);

    if (this.$attrs.muted !== undefined) this.handleToggleVideoMutedState();
  },
  beforeDestroy() {
    this.observer?.disconnect();
    this.observer = null;
  },
  watch: {
    videoElement(element) {
      if (element) this.setupObserver();
    },
    isShowTrimmer() {
      this.videoElement.currentTime = 0;
    },
    source() {
      this.isError = false;
      this.videoElement = this.$refs.video;
      this.videoDuration = this.videoElement.duration;
      this.currentTime = 0;
    }
  },
  methods: {
    getTimeAsMMSS,
    setupObserver() {
      this.observer = new IntersectionObserver(this.handleVideoPlayerIntersection, { threshold: 0.2 });
      this.observer.observe(this.$refs.video);
    },
    handleVideoPlayerIntersection([ entry = {} ]) {
      if (!entry.isIntersecting && !this.$refs.video.paused) {
        this.pauseVideo();
      }
    },
    handleToggleVideoPlayState() {
      if (!this.videoDuration) return;

      this.$refs.video.paused
        ? this.playVideo()
        : this.pauseVideo();
    },
    playVideo() {
      if (this.isShowTrimmer && (this.currentTime === this.trimEndTime)) {
        return this.videoElement.currentTime = this.trimStartTime;
      }

      this.$refs.video.play();
      this.isPlayingVideo = true;
    },
    pauseVideo() {
      this.$refs.video.pause();
      this.isPlayingVideo = false;
    },
    handleError() {
      this.isError = true;
      this.isLoading = false;
      this.isBuffering = false;
    },
    handleSeek(seekTime) {
      this.videoElement.currentTime = seekTime;
      this.currentTime = seekTime;
    },
    handlePlayOverflow() {
      this.pauseVideo();
      this.videoElement.currentTime = this.trimEndTime;
    },
    handleTrimStart(trimStartTime) {
      this.trimStartTime = trimStartTime;
      this.currentTime = trimStartTime;
      this.videoElement.currentTime = trimStartTime;
      this.$emit('trim-start', trimStartTime);
    },
    handleTrimEnd(trimEndTime) {
      this.trimEndTime = trimEndTime;
      this.currentTime = trimEndTime;
      this.videoElement.currentTime = trimEndTime;
      this.$emit('trim-end', trimEndTime);
    },
    handleToggleVideoMutedState() {
      if (!this.videoElement) return;

      this.videoElement.muted = !this.videoElement.muted;
      this.isVideoMuted = this.videoElement.muted;
    },
    handleProgressBarClick(event) {
      const playPercentage = (event.layerX / this.$refs.progress.clientWidth);

      this.videoElement.currentTime = this.videoElement.duration * playPercentage;
    },
    handleMetadataLoad() {
      this.endTime = this.videoElement.duration;
      this.videoDuration = this.videoElement.duration;
      this.startTime = 0;
      this.isBuffering = false;
    },
    handleCurrentTimeUpdate() {
      this.startTime = this.videoElement.currentTime;
      this.currentTime = this.videoElement.currentTime;
    },
    handleDurationChange() {
      this.videoDuration = this.videoElement.duration;
      this.endTime = this.videoElement.duration;
    },
    handleRequestFullScreen() {
      try {
        this.$refs.video.requestFullscreen();
      } catch (error) {
        return;
      }
    }
  },
}

</script>

<style lang="scss">

.video-media-card {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100%;
    max-width: 100%;
    font-family: var(--font-stack-secondary);
  }

  &__video-wrapper {
    --maxHeight: 400px;

    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 100%;
    max-height: var(--maxHeight);
  }

  &__video {
    flex: 1 1 100%;
    max-height: 800px;
    object-fit: contain;

    &--rounded-borders {
      border-radius: var(--rounded-xs);
    }
  }

  &__controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    min-height: var(--space-main-height);
    display: flex;
    align-items: center;
    padding: var(--space-base) var(--space-lg);
    border-radius: var(--rounded-base);
    background: linear-gradient(0deg, hsla(0,0%,0%,.50) 0%, hsla(0,0%,0%,0) 100%);
  }

  &__progress-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
  }

  &__progress {
    height: 2px;
    flex: 1 0 auto;
    border-radius: var(--rounded-base);
    cursor: pointer;
    -webkit-appearance: none;

    &::after {
      content: '';
      position: absolute;
      height: 20px;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
    }

    &::-webkit-progress-bar {
      background-color: var(--color-grey-light);
      border-radius: var(--rounded-base);
    }

    &::-webkit-progress-value {
      background-color: var(--color-primary);
    }

    &::-webkit-progress-inner-element {
      border-radius: var(--rounded-base);
    }
  }

  &__progress-time {
    position: absolute;
    font-size: var(--text-xs);
    font-weight: var(--font-bold);
    color: var(--color-grey-light);
    text-shadow: 0px 0px 4px var(--color-black);

    &--start {
      left: 0;
      transform: translateY(calc(-1.2 * var(--space-base)));
    }

    &--end {
      right: 0;
      transform: translateY(calc(-1.2 * var(--space-base)));
    }
  }

  &__infos {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--space-lg);
  }

  &__play-cta {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    filter: drop-shadow(0px 0px 6px hsla(0, 0%, 0%, .5));
  }

  &__error {
    text-align: center;
    text-shadow: 0px 0px 4px var(--color-black);
    color: var(--color-white);
  }

  &__actions-wrapper {
    position: absolute;
    top: var(--space-md);
    right: var(--space-md);
    display: flex;
    align-items: center;
    transform: translate(5px, -10px);
  }

  &__trim-progress-time {
    position: absolute;
    user-select: none;
    pointer-events: none;
    transform: translate(-50%, 125%);
  }

  &__trim-playhead-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateY(-150%);
    cursor: move;
  }

  &__trim-playhead {
    height: 12px;
    width: 12px;
    border: none;
    background-color: var(--color-black);
    transform: rotate(180deg);
    -webkit-clip-path: polygon(50% 0,0 100%,100% 100%);
    clip-path: polygon(50% 0,0 100%,100% 100%);
  }

  &__trim-progress {
    height: 4px;
    border-radius: var(--rounded-xs);
    background-color: var(--color-grey-light);

    &--playing {
      position: absolute;
      left: 0;
      height: 4px;
      border-radius: var(--rounded-xs);
      background-color: var(--color-grey-dark);
      transform: translateY(-100%);
      transition: right .5s ease-out;
    }
  }

  &__trim-timeline {
    z-index: 1;
    position: absolute;
    height: 4px;
    background-color: var(--color-black);
    transition: none;
  }

  &__trim-timeline-playing {
    position: absolute;
    height: 4px;
    border-radius: var(--rounded-xs);
    background-color: var(--color-grey-dark);
    transition: right .5s ease-out;
  }

  &__trim-handle {
    z-index: 100;
    position: absolute;
    height: var(--space-base);
    width: var(--space-base);
    background-color: var(--color-white);
    border: 4px solid var(--color-black);
    border-radius: var(--rounded-circle);
    transform: translate(-50%, -50%);
  }
}

</style>
