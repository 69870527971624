<template>
  <div class="artist-profile-cover__wrapper">
    <Loader
      v-if="isLoadingBannerPicture"
      :height="300">
    </Loader>
    <CloudinaryUploadWidget
      v-else
      @upload-success="handleBannerPictureUpload"
      :uploadOptions="uploadBannerPictureOptions"
      #default="{ createAndOpenWidget }"
      id="banner-picture">
      <figure
        @click="isPublic ? isLightboxOpen = true : createAndOpenWidget()"
        :style="{ cursor: 'pointer' }"
        class="artist-profile-cover__picture-wrapper">
        <template v-if="croppedBannerPictureUrl">
          <img
            :src="croppedBannerPictureUrl"
            alt="Banner"
            class="artist-profile-cover__picture">
          <Button
            v-if="!isPublic"
            isWhite
            hasNoBorder
            type="button"
            class="artist-profile-cover__edit-cta">
            <Icon
              :variant="'photo-outline'"
              :size="'xs'"
              class="mr-base">
            </Icon>
            {{ $t('artist.dashboard.menu.header.updateBannerPicture') }}
          </Button>
        </template>
        <template v-else>
          <Paragraph
            :size="'sm'"
            class="artist-profile-cover__picture-hint">
            {{ $t('artist.dashboard.menu.header.addBannerPicture') }}
          </Paragraph>
          <Button
            isWhite
            hasNoBorder
            type="button"
            class="artist-profile-cover__edit-cta">
            <Icon
              :variant="'photo-outline'"
              :size="'xs'"
              class="mr-base">
            </Icon>
            {{ $t('artist.dashboard.menu.header.addBannerPicture') }}
          </Button>
        </template>
      </figure>
    </CloudinaryUploadWidget>
    <Lightbox
      v-if="isLightboxOpen"
      @close-lightbox="isLightboxOpen = false"
      :items="[croppedBannerPictureUrl]">
      <template #default="{ item }">
        <img
          :src="item"
          alt="Artist picture">
      </template>
    </Lightbox>
  </div>
</template>

<script>

import {
  mapState,
  mapActions,
}                                         from 'vuex';

import Loader                             from '../../../components/atoms/Loader/a-Loader.vue';
import Icon                               from '../../../components/atoms/Icon/a-Icon.vue';
import Button                             from '../../../components/atoms/Button/a-Button.vue';
import Paragraph                          from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import CloudinaryUploadWidget             from '../../../components/molecules/CloudinaryUploadWidget/m-CloudinaryUploadWidget.vue';
import Lightbox                           from '../../../components/molecules/Lightbox/m-Lightbox.vue';
import {
  widgetUploadPictureOptions,
  widgetUploadBannerPictureOptions,
}                                         from '../../../constants/cloudinary-upload-options.js';
import { getCroppedPictureURL }           from '../../../utils/URLUtils.js';


export default {
  name: 'o-ArtistProfileCover',
  components: {
    Loader,
    Icon,
    Button,
    Lightbox,
    Paragraph,
    CloudinaryUploadWidget,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isLightboxOpen: false,
  }),
  computed: {
    ...mapState('User', ['user', 'isLoadingBannerPicture']),
    ...mapState('SearchArtist', ['currentArtist']),
    croppedBannerPictureUrl() {
      const { URLBannerPictures, BannerPicturesCropsOptions } = this.isPublic ? this.currentArtist : this.user;

      return this.getCroppedPictureURL(URLBannerPictures, BannerPicturesCropsOptions, { q: 100, }, { c: 'fill', w: 1320, h: 385 });
    },
    uploadBannerPictureOptions() {
      return { ...widgetUploadPictureOptions, ...widgetUploadBannerPictureOptions };
    },
  },
  methods: {
    getCroppedPictureURL,
    ...mapActions({
      uploadBannerPicture: 'User/UPLOAD_BANNER_PICTURE',
    }),
    async handleBannerPictureUpload({ url, cropOptions }) {
      const uploadBannerPictureParams = {
        input: {
          url,
          options: cropOptions,
          type: 'BANNER',
        },
      };

      await this.uploadBannerPicture(uploadBannerPictureParams);
    },
  },
}

</script>

<style lang="scss">

.artist-profile-cover {
  &__wrapper {
    position: relative;
    grid-column: 1 / -1;
    grid-row: 2 / 3;
    height: 350px;
    width: 100%;

    @media screen and ($desktop) {
      grid-column: 1 / -1;
    }
  }

  &__picture-wrapper {
    height: 100%;
    background-color: var(--color-grey-empty);

    @media screen and ($desktop) {
      border: 1px solid var(--color-grey-light);
      border-radius: var(--rounded-xl);
    }
  }

  &__picture {
    min-height: 100%;
    max-height: 100%;
    min-width: 100%;
    max-width: 100%;
    object-fit: cover;

    @media screen and ($desktop) {
      border-radius: var(--rounded-xl);
    }

    &--empty {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: var(--color-black);
      border-radius: var(--rounded-xl);
    }
  }

  &__picture-hint {
    z-index: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__edit-cta {
    position: absolute;
    left: var(--space-lg);
    bottom: var(--space-lg);
  }
}

</style>
