<template>
  <Modal @close-modal="$emit('close-modal')">
    <form
      @submit.prevent="handleSubmit"
      novalidate
      class="artist-profile-upload-pictures__wrapper">
      <header class="artist-profile-upload-pictures__header">
        <Heading
          :tag="'h2'"
          :level="'h3'"
          class="mr-md">
          {{ $t('artist.dashboard.menu.header.editPerformance.media.photoMedia.title') }}
        </Heading>
        <Paragraph>
          {{ $t('artist.dashboard.menu.header.editPerformance.media.photoMedia.subTitle') }}
        </Paragraph>
      </header>

      <div class="artist-profile-upload-pictures__list-wrapper">
        <span
          v-for="(picture, index) in picturesToDisplay"
          :key="`picture-${index}`"
          class="artist-profile-upload-pictures__index">
          {{ index + 1 }}.
        </span>
        <SortableList @sort="handlePicturesSort">
          <SortableItem
            v-for="(picture, index) in picturesToDisplay"
            :isHideHandle="(loadingPictureIDs.includes(picture.ID)) || picture.isLoading"
            :key="`picture-${index}`">
            <Loader
              v-if="picture.isLoading || (loadingPictureIDs.includes(picture.ID))"
              :size="'sm'"
              class="mx-auto">
            </Loader>
            <template v-else>
              <img
                :src="picture.URL"
                :alt="picture.Type"
                class="artist-profile-upload-pictures__thumbnail">
              <button
                @click="handlePictureDelete(picture.ID)"
                type="button"
                class="artist-profile-upload-pictures__delete-cta">
                {{ $t('common.delete') }}
                <Icon
                  :variant="'cross-outline'"
                  :size="'xs'"
                  class="ml-sm">
                </Icon>
              </button>
            </template>
          </SortableItem>
        </SortableList>
      </div>

      <Loader
        v-if="isLoading"
        :class="$mediaQueries.isDesktop ? 'mt-lg' : 'mt-auto'">
      </Loader>
      <template v-else>
        <template v-if="picturesToDisplay.length < maxPicturesUpload">
          <CloudinaryUploadWidget
            @upload-success="handlePictureUpload"
            :uploadOptions="widgetUploadPictureOptions"
            #default="{ createAndOpenWidget }">
            <Button
              @click="createAndOpenWidget"
              isWhite
              type="button"
              :class="$mediaQueries.isDesktop ? 'my-lg' : 'my-auto'">
              <Icon
                :variant="'plus-outline'"
                :size="'sm'"
                class="mr-sm">
              </Icon>
              {{ $t('artist.dashboard.menu.header.editPerformance.media.photoMedia.addOther') }}
            </Button>
          </CloudinaryUploadWidget>
          <Paragraph
          :size="'sm'"
          class="mb-base">
            {{ $t('artist.dashboard.menu.header.editPerformance.media.photoMedia.warningPhoto') }}
          </Paragraph>
        </template>
        <Paragraph
          v-else
          :size="'sm'"
          class="mb-base">
          {{ $t('artist.dashboard.menu.header.editPerformance.media.photoMedia.maxPhotos') }}
        </Paragraph>
        <Button type="submit">
          {{ $t('common.validate') }}
        </Button>
      </template>
    </form>
  </Modal>
</template>

<script>

import { mapActions, mapGetters }             from 'vuex';


import Loader                                 from '../../../components/atoms/Loader/a-Loader.vue';
import Modal                                  from '../../../components/atoms/Modal/a-Modal.vue';
import Heading                                from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                              from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button                                 from '../../../components/atoms/Button/a-Button.vue';
import Icon                                   from '../../../components/atoms/Icon/a-Icon.vue';
import SortableList                           from '../../../components/molecules/SortableList/m-SortableList.vue';
import SortableItem                           from '../../../components/molecules/SortableItem/m-SortableItem.vue';
import CloudinaryUploadWidget                 from '../../../components/molecules/CloudinaryUploadWidget/m-CloudinaryUploadWidget.vue';
import {
  widgetUploadPictureOptions,
}                                             from '../../../constants/cloudinary-upload-options.js';


export default {
  name: 'o-ArtistProfileUploadPictures',
  components: {
    Loader,
    Modal,
    Heading,
    Paragraph,
    Button,
    Icon,
    SortableList,
    SortableItem,
    CloudinaryUploadWidget,
  },
  data: () => ({
    isLoading: false,
    maxPicturesUpload: 4,
    hasUpdatedRanking: false,
    widgetUploadPictureOptions,
    loadingPictureIDs: [],
    pendingUploads: [],
    picturesRanking: [],
  }),
  computed: {
    ...mapGetters({ userPictures: 'User/MEDIAS_IMAGES' }),
    picturesToDisplay() {
      return [
        ...this.userPictures,
        ...this.pendingUploads,
      ];
    },
  },
  watch: {
    userPictures: {
      immediate: true,
      handler(currentPictures, previousPictures) {
        const hasUploadedNewPicture = Boolean(previousPictures?.length && (currentPictures.length > previousPictures.length));
        const hasDeletedPicture = Boolean(previousPictures?.length && (currentPictures.length < previousPictures.length));

        if (hasUploadedNewPicture) this.picturesRanking.push(currentPictures[currentPictures.length - 1].ID);
        else if (hasDeletedPicture) {
          const { ID: deletePictureID } = previousPictures.find(({ ID }) => !currentPictures.some(currentPicture => currentPicture.ID === ID));
          const deletedPictureIndex = this.picturesRanking.findIndex(ID => ID === deletePictureID);

          this.picturesRanking.splice(deletedPictureIndex, 1);
        }
        else this.picturesRanking = this.userPictures.map(({ ID }) => ID);
      },
    },
  },
  methods: {
    ...mapActions({
      uploadMedia: 'User/UPLOAD_MEDIA',
      deleteMedia: 'User/DELETE_MEDIA',
      updateMediaRanking: 'User/UPDATE_MEDIA_RANKING',
    }),
    handlePicturesSort({ newIndex, oldIndex }) {
      this.picturesRanking.splice(newIndex, 0, this.picturesRanking.splice(oldIndex, 1)[0]);
      this.hasUpdatedRanking = true;
    },
    async handleSubmit() {
      if (this.hasUpdatedRanking) {
        const params = this.picturesRanking.map((ID, index) => ({ id: ID, rank: index }));

        try {
          this.isLoading = true;

          await this.updateMediaRanking(params);
        } finally {
          this.isLoading = false;
        }
      }

      this.$emit('close-modal');
    },
    async handlePictureUpload({ url, cropOptions = {} }) {
      const params = {
        input: {
          type: 'IMAGE',
          url,
          options: cropOptions,
          rank: this.userPictures.length,
        },
      };

      this.pendingUploads.push({ URL: '', isLoading: true });

      try {
        await this.uploadMedia(params);
      } finally {
        this.pendingUploads = [];
      }
    },
    async handlePictureDelete(mediaID) {
      const params = { id: mediaID, };

      try {
        this.loadingPictureIDs.push(mediaID);

        await this.deleteMedia(params);
      } finally {
        this.loadingPictureIDs = this.loadingPictureIDs.filter(id => id !== mediaID);
      }
    },
  },
}

</script>

<style lang="scss">

.artist-profile-upload-pictures {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: var(--space-md);

    @media screen and ($desktop) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__list-wrapper {
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-template-rows: 80px;
    grid-auto-rows: 80px;
    grid-row-gap: var(--space-xs);
    align-items: end;
    width: 100%;
    margin-bottom: var(--space-md);

    @media screen and ($desktop) {
      grid-template-columns: 40px minmax(700px, 1fr);
    }
  }

  &__index {
    grid-column: 1 / 2;
    align-self: center;
    width: 40px;
    font-family: var(--font-stack-secondary);
    font-size: var(--text-sm);
    font-weight: var(--font-light);
  }

  &__thumbnail {
    height: 40px;
    width: 60px;
    object-fit: cover;
    border-radius: var(--rounded-xs);
  }

  &__delete-cta {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: var(--text-xs);
    font-family: var(--font-stack-secondary);
    font-weight: var(--font-bold);
    line-height: 2;
    text-decoration: underline;
    text-underline-offset: var(--space-xs);
    color: var(--color-black);
  }
}

</style>
