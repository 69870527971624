<template>
  <section class="artist-profile-publish-cta__wrapper">
    <header class="mb-md">
      <Loader
        v-if="isLoading"
        class="mr-auto">
      </Loader>
      <Button
        v-else
        @click="handleProfilePublish"
        type="button">
        {{ $t('artist.dashboard.menu.profilePublishTopCard.cta') }}
      </Button>
    </header>
    <Paragraph
      v-if="isMandatoryError"
      :color="'alert'"
      class="artist-profile-publish-cta__error-text">
      {{ $t('artist.dashboard.menu.profilPublish.errors.incompleteProfile') }}
    </Paragraph>
    <Paragraph
      :tag="'h2'"
      :size="'sm'">
      {{ $t('artist.dashboard.menu.profilePublishTopCard.description') }}
    </Paragraph>
  </section>
</template>

<script>

import {
  mapActions,
  mapGetters,
}                     from 'vuex';

import Loader         from '../../../components/atoms/Loader/a-Loader.vue';
import Button         from '../../../components/atoms/Button/a-Button.vue';
import Paragraph      from '../../../components/atoms/Paragraph/a-Paragraph.vue';


export default {
  name: 'o-ArtistProfilePublishCta',
  components: {
    Loader,
    Button,
    Paragraph,
  },
  data: () => ({
    isLoading: false,
    isMandatoryError: false,
  }),
  computed: {
    ...mapGetters({
      canPublishProfile: 'User/CAN_PUBLISH_PROFILE',
    }),
  },
  methods: {
    ...mapActions({ toggleArtistPublish: 'User/TOGGLE_PUBLISH_ARTIST' }),
    async handleProfilePublish() {
      if (!this.canPublishProfile) return this.isMandatoryError = true;

      try {
        this.isLoading = true;

        await this.toggleArtistPublish();
      } finally {
        this.isLoading = false;
      }
    },
  },
}

</script>

<style lang="scss">

.artist-profile-publish-cta {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: var(--space-lg);
    background-color: var(--color-white);
    border-radius: var(--rounded-xl);
  }

  &__hint {
    margin-bottom: var(--space-sm);
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xs);
    font-weight: var(--font-bold);
  }

  &__error-text {
    grid-column: 1 / -1;
    margin-top: var(--space-xxs);
    margin-bottom: var(--space-sm);
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xxs);
    font-weight: 600;
    line-height: var(--lineheight-xs);
  }
}

</style>
