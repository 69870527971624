<template>
  <div class="range-selector__wrapper">
    <label
      v-if="label"
      :for="id"
      class="range-selector__label">
      {{ label }}
    </label>
    <div class="range-selector__container">
      <input
        v-bind="$attrs"
        v-on="{
          ...$listeners,
          input: handleInput,
        }"
        :value="value"
        type="range"
        class="range-selector__input">
      <div class="range-selector__input-line"></div>
      <div
        :style="activeLineStyle"
        class="range-selector__active-line">
      </div>
      <div class="range-selector__input-line-dots-wrapper">
        <div
          v-for="(step, index) in numberOfSteps"
          :key="`dot-${index}`"
          :class="[
            { 'range-selector__input-line-dot--active': step <= value },
            'range-selector__input-line-dot',
          ]">
        </div>
      </div>
      <output
        :style="outputStyle"
        for="edit-performance-duration-range"
        class="range-selector__output">
        <slot name="output">
          {{ value }} €
        </slot>
      </output>
    </div>
  </div>
</template>

<script>

export default {
  name: 'm-RangeSelector',
  inheritAttrs: false,
  props: {
    numberOfSteps: {
      type: Number,
      required: true,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    intervalValue() {
      return 100 / (this.numberOfSteps - 1);
    },
    // activeLineStyle() {
    //   const valuePercentage = (this.value - 1) * this.intervalValue;
    //   const leftValue = `${valuePercentage}%`;

    //   return { 'width': leftValue };
    // },
    // outputStyle() {
    //   const valuePercentage = (this.value - 1) * this.intervalValue;
    //   const leftValue = `${valuePercentage}%`;
    //   const translateX = `translateX(-${valuePercentage}%)`;

    //   return { 'left': `${leftValue}`, 'transform': translateX, };
    // },
    activeLineStyle() {
      // return { 'width': `${(this.value / this.$attrs.max) * 100}%` };
      const valuePercentage = Math.abs((this.value / this.$attrs.max) * 100).toFixed(2);
      const leftValue = `${valuePercentage}%`;

      return { 'width': leftValue };
    },
    outputStyle() {
      // const valuePercentage = (this.value - 1) * this.intervalValue;
      // const leftValue = `${valuePercentage}%`;
      // const translateX = `translateX(-${valuePercentage}%)`;

      return { 'left': `${(this.value / Number(this.$attrs.max)) * 100}%`, };
    },
  },
  methods: {
    handleInput(event) {
      this.$emit('input', Number(event.target.value));
    },
  },
}

</script>

<style lang="scss">

.range-selector {
  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: var(--space-lg);
  }

  &__container {
    position: relative;
    display: flex;
    flex: 1 0 auto;
  }

  &__label {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-xs);
    font-family: var(--font-stack-secondary);
    font-size: var(--text-xs);
    font-weight: var(--font-bold);
    line-height: var(--lineheight-md);
    color: var(--color-black);

    @include textOverflowEllipsis();
  }

  &__input {
    z-index: 1;
    position: relative;
    flex: 1;
    background-color: transparent;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 14px;
      width: 14px;
      border-radius: var(--rounded-circle);
      background-color: var(--color-white);
      border: 3px solid var(--color-black);
      cursor: pointer;
    }

    &::-moz-range-thumb {
      -moz-appearance: none;
      height: 14px;
      width: 14px;
      border-radius: var(--rounded-circle);
      background-color: var(--color-white);
      border: 3px solid var(--color-black);
      cursor: pointer;
    }
  }

  &__input-line {
    position: absolute;
    height: 4px;
    top: 50%;
    width: 100%;
    background-color: var(--color-grey-semi);
    transform: translateY(-50%);
    border-radius: 2px;
    transition: width;
  }

  &__active-line {
    position: absolute;
    left: 0;
    top: 50%;
    height: 4px;
    transform: translateY(-50%);
    background-color: var(--color-black);
  }

  &__input-line-dots-wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }

  &__input-line-dot {
    height: 14px;
    width: 14px;
    left: 0;
    border-radius: var(--rounded-circle);
    background-color: var(--color-black);
    border: 3px solid var(--color-white);
    cursor: pointer !important;

    &--active {
      background-color: var(--color-grey-light);
      border: 3px solid var(--color-black);
    }
  }

  &__output {
    position: absolute;
    top: 20px;
    padding-right: var(--space-xxs);
    font-size: var(--text-xs);
    font-style: italic;
    color: var(--color-grey-neutral);
    transform: translateX(-50%);

    @include textOverflowEllipsis();
  }
}

</style>
