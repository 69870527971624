export default [
	{
		label: 'languagesList.mandarin',
		value: 'MANDARIN'
	},
	{
		label: 'languagesList.spanish',
		value: 'SPANISH'
	},
	{
		label: 'languagesList.english',
		value: 'ENGLISH'
	},
	{
		label: 'languagesList.hindi',
		value: 'HINDI'
	},
	{
		label: 'languagesList.arabic',
		value: 'ARABIC'
	},
	{
		label: 'languagesList.portuguese',
		value: 'PORTUGUESE'
	},
	{
		label: 'languagesList.russian',
		value: 'RUSSIAN'
	},
	{
		label: 'languagesList.japanese',
		value: 'JAPANESE'
	},
	{
		label: 'languagesList.german',
		value: 'GERMAN'
	},
	{
		label: 'languagesList.javanese',
		value: 'JAVANESE'
	},
	{
		label: 'languagesList.korean',
		value: 'KOREAN'
	},
	{
		label: 'languagesList.french',
		value: 'FRENCH'
	},
	{
		label: 'languagesList.turkish',
		value: 'TURKISH'
	},
	{
		label: 'languagesList.italian',
		value: 'ITALIAN'
	},
	{
		label: 'languagesList.dutch',
		value: 'DUTCH'
	}
];