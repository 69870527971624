<template>
  <MainLayout class="main-layout--artist-profile">
    <Loader
      v-if="user.isUserLoading"
      :height="$mediaQueries.isDesktop ? 800 : 300"
      class="artist-profile__loader">
    </Loader>

    <template v-else>
      <ArtistProfileHeader :artist="user"></ArtistProfileHeader>
      <ArtistProfileInfos :artist="user"></ArtistProfileInfos>
      <ArtistProfilePerformance
        :artist="user"
        ref="performance">
      </ArtistProfilePerformance>
      <div class="artist-profile__sidebar">
        <div class="artist-profile__sticky">
          <!-- TODO: Lazy load v-if components -->
          <ArtistProfilePublishCta
            v-if="!user.IsActive && !user.IsBanned"
            :artist="user">
          </ArtistProfilePublishCta>
          <ArtistProfilePricing :artist="user"></ArtistProfilePricing>
          <ArtistProfileProgress @progress-item-click="handleProgressItemClick"></ArtistProfileProgress>
        </div>
      </div>
      <ArtistProfileMedias :artist="user"></ArtistProfileMedias>
      <ArtistProfileFAQ
        :artist="user"
        ref="faq">
      </ArtistProfileFAQ>
      <ArtistProfileAgenda></ArtistProfileAgenda>
    </template>
  </MainLayout>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapActions
}                                   from 'vuex';

import MainLayout                   from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Loader                       from '../../../components/atoms/Loader/a-Loader.vue';
import ArtistProfileHeader          from './o-ArtistProfileHeader.vue';
import ArtistProfileInfos           from './o-ArtistProfileInfos.vue';
import ArtistProfilePerformance     from './o-ArtistProfilePerformance.vue';
import ArtistProfilePricing         from './o-ArtistProfilePricing.vue';
import ArtistProfileProgress        from './o-ArtistProfileProgress.vue';
import ArtistProfileFAQ             from './o-ArtistProfileFAQ.vue';
import ArtistProfilePublishCta      from './o-ArtistProfilePublishCta.vue';
import ArtistProfileAgenda          from './o-ArtistProfileAgenda.vue';
import ArtistProfileMedias          from './o-ArtistProfileMedias.vue';


export default {
  name: 'p-ArtistProfile',
  components: {
    MainLayout,
    Loader,
    ArtistProfileHeader,
    ArtistProfileInfos,
    ArtistProfilePerformance,
    ArtistProfilePricing,
    ArtistProfileProgress,
    ArtistProfileFAQ,
    ArtistProfilePublishCta,
    ArtistProfileAgenda,
    ArtistProfileMedias,
  },
  computed: {
    ...mapState('User', ['user']),
    ...mapGetters({
      hasSetupActivityType: 'User/HAS_SET_ACTIVITY',
      availabilities: 'Availabilities/CALENDAR_AVAILABILITIES',
    }),
  },
  async created() {
    await this.fetchMe();
  },
  methods: {
    ...mapActions({ fetchMe: 'User/FETCH_ME' }),
    handleProgressItemClick({ value }) {
      this.$refs.performance.isEditingPerformance = false;
      this.$refs.faq.isQuestionsListOpen = false;

      if (!['performance', 'faq'].includes(value)) return;
      if (value === 'performance') return this.$refs.performance.isEditingPerformance = true;
      if (value === 'faq') return this.$refs.faq.isQuestionsListOpen = true;
    },
  },
}

</script>

<style lang="scss">

.artist-profile {
  &__loader {
    grid-column: 1 / -1;
  }

  &__sidebar {
    grid-column: 1 / 2;
    grid-row: 3 / 4;

    @media screen and ($desktop) {
      grid-column: 2 / 3;
      grid-row: 2 / -1;
    }
  }

  &__sticky {
    @media screen and ($desktop) {
      position: sticky;
      top: calc(var(--space-header-height) + var(--space-lg));
    }

    > *:not(:last-child) {
      margin-bottom: var(--space-md);
    }
  }
}

</style>
