<template>
  <div
    v-if="isVisible"
    @mouseleave="handleMouseLeave"
    class="tooltip__wrapper">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'a-Tooltip',
  props: {
    trigger: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isVisible: false,
  }),
  watch: {
    trigger(vueComponent) {
      if (!vueComponent) return;

      this.trigger.$el.addEventListener('mouseenter', this.handleMouseEnter);
      this.$el.parentElement.style.position = 'relative';
    },
  },
  beforeDestroy() {
    this.trigger?.$el?.removeEventListener('mouseenter', this.handleMouseEnter);
  },
  methods: {
    handleMouseEnter() {
      this.isVisible = true;
    },
    handleMouseLeave() {
      this.isVisible = false;
    },
  },
}

</script>

<style lang="scss">

.tooltip {
  &__wrapper {
    z-index: 10;
    position: absolute;
    top: var(--space-lg);
    right: 0;
    padding: var(--space-lg);
    border-radius: var(--rounded-xl);
    background-color: var(--color-white);
    box-shadow: 0 0 20px 1px hsla(0, 0%, 0%, .05);
  }
}

</style>
