<template>
  <ol class="sortable-list__list">
    <slot></slot>
  </ol>
</template>

<script>

import Sortable     from 'sortablejs/modular/sortable.core.esm.js';


export default {
  name: 'm-SortableList',
  data: () => ({
    sortable: null,
  }),
  mounted() {
    this.setupSortable();
  },
  beforeDestroy() {
    this.sortable?.destroy();
    this.sortable = null;
  },
  methods: {
    setupSortable() {
      const options = {
        onSort: this.onSort,
        handle: '.sortable-item__handle',
      };

      this.sortable = new Sortable(this.$el, options);
    },
    onSort(event) {
      const { newIndex, oldIndex } = event;

      this.$emit('sort', { newIndex, oldIndex });
    }
  }
}

</script>

<style lang="scss">

.sortable-list {
  &__list {
    display: flex;
    flex-direction: column;
  }
}

</style>
