const widgetUploadDefaultOptions = {
  cloudName: process.env.VUE_APP_UPLOAD_CLOUDNAME,
  sources: ['local', 'instagram', 'facebook'],
  multiple: false,
  maxFiles: 1,
  showPoweredBy: false,
  showSkipCropButton: true,
  showCompletedButton: true,
  singleUploadAutoClose: false,
  maxFileSize: 250000000,
  styles: {
    palette: {
      window: "#FFFFFF",
      windowBorder: "#000000",
      tabIcon: "#000000",
      menuIcons: "#000000",
      textDark: "#000000",
      textLight: "#FFFFFF",
      link: "#000000",
      action: "#FFFFFF",
      inactiveTabIcon: "#0E2F5A",
      error: "#F44235",
      inProgress: "#0078FF",
      complete: "#20B832",
      sourceBg: "#FFFFFF",
    },
    fonts: {
      default: null,
      "'Be Vietnam', sans-serif": {
        url: "https://fonts.googleapis.com/css?family=Be+Vietnam",
        active: true,
      },
    },
  },
};

const widgetUploadProfilePictureOptions = {
  croppingAspectRatio: 1,
  croppingDefaultSelectionRatio: 1,
  minImageWidth: 100,
  minImageHeight: 100,
  clientAllowedFormats: ['jpeg', 'png', 'gif', 'webp'],
  resourceType: 'image',
};

const widgetUploadBannerPictureOptions = {
  croppingAspectRatio: 3.43,
  croppingDefaultSelectionRatio: 3.43,
  showSkipCropButton: false,
};

const widgetUploadPictureOptions = {
  uploadPreset: process.env.VUE_APP_IMAGE_UPLOAD_PRESET,
  cropping: true,
  croppingValidateDimensions: true,
  clientAllowedFormats: ['jpeg', 'png', 'gif', 'webp'],
  resourceType: 'image',
};

const widgetUploadVideoOptions = {
  uploadPreset: process.env.VUE_APP_VIDEO_UPLOAD_PRESET,
  clientAllowedFormats: ['mp4', 'mov', 'webm', 'avi'],
  resourceType: 'video',
};

const widgetUploadPrivateDocumentOptions = {
  uploadPreset: process.env.VUE_APP_DOCUMENT_UPLOAD_PRESET,
  sources: ['local'],
  clientAllowedFormats: ['jpeg', 'png', 'pdf', 'webp'],
  resourceType: 'image',
};


export {
  widgetUploadDefaultOptions,
  widgetUploadProfilePictureOptions,
  widgetUploadBannerPictureOptions,
  widgetUploadPictureOptions,
  widgetUploadVideoOptions,
  widgetUploadPrivateDocumentOptions,
};
