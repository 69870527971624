<template>
  <section class="artist-profile-edit-video__wrapper">
    <header class="artist-profile-edit-video__header">
      <Icon
        @click="$emit('close-edit')"
        :variant="'chevron-left-outline'"
        :tag="'button'"
        :size="'sm'"
        type="button"
        class="mr-md">
      </Icon>
      <Heading
        :level="'h3'"
        :tag="'h3'">
        {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.trimVideo') }}
      </Heading>
    </header>

    <Loader
      v-if="isLoading"
      :height="450"
      class="artist-profile-edit-video__loader">
    </Loader>

    <VideoMediaCard
      v-show="!isLoading"
      :source="video.originalURL"
      :isShowControls="false"
      @trim-start="trim => trimStart = trim"
      @trim-end="trim => trimEnd = trim"
      :existingTrim="video.options || null"
      isShowTrimmer>
    </VideoMediaCard>

    <Button
      v-if="!isLoading"
      @click="handleVideoTrim"
      type="button">
      {{ $t('common.validate') }}
    </Button>
  </section>
</template>

<script>

import { mapActions }     from 'vuex';

import Icon               from '../../../components/atoms/Icon/a-Icon.vue';
import Heading            from '../../../components/atoms/Heading/a-Heading.vue';
import Button             from '../../../components/atoms/Button/a-Button.vue';
import Loader             from '../../../components/atoms/Loader/a-Loader.vue';
import VideoMediaCard     from '../../../components/molecules/VideoMediaCard/m-VideoMediaCard.vue';


export default {
  name: 'o-ArtistProfileEditVideo',
  components: {
    Icon,
    Heading,
    Button,
    Loader,
    VideoMediaCard,
  },
  props: {
    video: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    isLoading: false,
    trimStart: 0,
    trimEnd: 0,
    videoDuration: 0,
    currentTime: 0,
  }),
  methods: {
    ...mapActions({
      uploadMedia: 'User/UPLOAD_MEDIA',
    }),
    async handleVideoTrim() {
      const { originalURL: url, options = {}, Rank } = this.video;
      const hasNotSetTrim = this.trimStart === 0 && this.trimEnd === 0;
      const hasNotModifiedTrim = (this.trimStart === options.so) && (this.trimEnd === options.eo);

      if (hasNotSetTrim || hasNotModifiedTrim) return this.$emit('close-edit');

      const params = {
        input: {
          type: 'VIDEO',
          url,
          rank: Rank,
          options: {
            so: this.trimStart,
            ...(this.trimEnd === 0 ? {} : { eo: this.trimEnd }),
          },
        },
      };

      try {
        this.isLoading = true;

        await this.uploadMedia(params);

        this.$emit('close-edit');
      } finally {
        this.isLoading = false;
      }
    },
  }
}

</script>

<style lang="scss">

.artist-profile-edit-video {
  &__wrapper {
    max-width: 100%;

    @media screen and ($desktop) {
      min-width: 600px;
      max-width: calc(400px * (16 / 9));
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-lg);
  }

  &__loader {
    width: 600px;
  }
}

</style>
