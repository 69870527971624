<script>

import { mapState }                       from 'vuex';

import { widgetUploadDefaultOptions }     from '../../../constants/cloudinary-upload-options.js';
import fr                                 from '../../../translations/fr.js';


export default {
  name: 'm-CloudinaryUploadWidget',
  props: {
    uploadOptions: {
      type: Object,
      default: () => ({}),
    }
  },
  data: () => ({
    widget: {},
  }),
  computed: {
    ...mapState('User', ['locale']),
    baseOptions() {
      return {
        ...widgetUploadDefaultOptions,
        text: { fr: fr.cloudinaryWidget },
        language: this.locale?.locale ?? 'en',
      };
    },
  },
  beforeDestroy() {
    this.widget = {};
  },
  methods: {
    createAndOpenWidget(files) {
      this.widget = window.cloudinary.createUploadWidget({ ...this.baseOptions, ...this.uploadOptions }, this.onCloudinaryWidgetEvent);
      this.widget.open(files);
    },
    onCloudinaryWidgetEvent(error, result) {
      if (error) return this.$emit('error', error);
      if (result.event === 'close') return this.widget = {};
      if (result.event !== 'success') return;

      const uploadPayload = { url: result.info.resource_type === 'video' ? result.info.public_id : result.info.secure_url };
      const hasImageCropOptions = (result.info.resource_type === 'image') && (result.info.coordinates);

      if (hasImageCropOptions) {
        const { coordinates: { custom: [options] } } = result.info;
        const [ x, y, w, h ] = options;

        uploadPayload.cropOptions = { x, y, w, h };
      }

      this.$emit('upload-success', uploadPayload);
    },
  },
  render() {
    return this.$scopedSlots.default({
      widget: this.widget,
      createAndOpenWidget: this.createAndOpenWidget,
    });
  }
}

</script>
