var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"artist-profile-progress__wrapper",attrs:{"id":"progress"}},[_c('header',{staticClass:"artist-profile-progress__header"},[_c('Heading',{attrs:{"level":'h4',"tag":'h4'}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.profilPublish.title', { percentComplete: _vm.progress }))+" ")])],1),(_vm.user.IsActive)?_c('TextLink',{staticClass:"artist-profile-progress__public-profile",attrs:{"urlPath":("/artist/profile/public/" + (_vm.user.ID))}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.profilPublish.publicPreview'))+" ")]):_vm._e(),(_vm.user.IsActive && !_vm.user.IsBanned)?_c('Paragraph',{staticClass:"artist-profile-progress__published-hint",attrs:{"size":'sm'}},[_c('Icon',{staticClass:"mr-xs",attrs:{"variant":'check-outline',"size":'xs'}}),_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.profilPublish.profilePublished'))+" ")],1):_vm._e(),(!_vm.hasCompletedSteps)?_c('div',{staticClass:"artist-profile-progress__progress-list-wrapper"},[_c('Heading',{staticClass:"ml-xxs mb-sm",attrs:{"tag":'h4',"level":'h5'}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.profilPublish.todoTitle'))+" : ")]),_c('ul',{staticClass:"artist-profile-progress__progress-list"},_vm._l((_vm.progressList),function(progressItem,index){return _c('li',{key:("progress-item-" + index),staticClass:"artist-profile-progress__progress-item",on:{"click":function($event){return _vm.$emit('progress-item-click', progressItem)}}},[_c('router-link',{staticClass:"artist-profile-progress__progress-link",attrs:{"to":progressItem.link}},[_c('Icon',{class:[
              progressItem.done ? 'mr-xs' : 'mr-sm',
              progressItem.done ? 'ml-xxs' : 'ml-xs' ],attrs:{"variant":progressItem.done ? 'check-outline' : 'plus-outline',"color":progressItem.done
              ? 'gradient'
              : progressItem.isMandatory
                ? 'alert'
                : 'black',"size":progressItem.done ? 'sm' : 'xxs',"isCircled":!progressItem.done}}),_c('Paragraph',{attrs:{"size":'md',"tag":'span',"color":progressItem.done || !progressItem.isMandatory ? 'black' : 'alert',"isBold":""}},[_vm._v(" "+_vm._s(_vm.$t(progressItem.label))+" ")])],1)],1)}),0)],1):_vm._e(),_c('div',{staticClass:"artist-profile-progress__progress-indicator-wrapper"},[_c('Heading',{attrs:{"level":'h1',"tag":'span'}},[_vm._v(" "+_vm._s(_vm.progress)+" ")]),_vm._l((_vm.thresholds),function(threshold,index){return [(threshold && (_vm.progress >= threshold))?_c('div',{key:("progress-threshold-" + index),class:[
          'artist-profile-progress__progress-indicator',
          ("artist-profile-progress__progress-indicator--" + threshold) ]}):_vm._e()]})],2),_c('Paragraph',{staticClass:"artist-profile-progress__text"},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.profilPublish.description'))+" ")]),(_vm.isMandatoryError)?_c('Paragraph',{staticClass:"artist-profile-progress__error-text",attrs:{"color":'alert'}},[_vm._v(" "+_vm._s(_vm.$t('artist.dashboard.menu.profilPublish.errors.incompleteProfile'))+" ")]):_vm._e(),(!_vm.user.IsBanned)?[(_vm.isLoading)?_c('Loader',{staticClass:"mr-auto mt-xs"}):[_c('Button',{staticClass:"artist-profile-progress__publish-cta",attrs:{"type":"button"},on:{"click":_vm.handleToggleProfilePublish}},[(!_vm.user.IsActive)?_c('Icon',{staticClass:"mr-base",attrs:{"variant":'view-outline',"color":'white',"size":'xs'}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t(_vm.user.IsActive ? 'artist.dashboard.menu.profilPublish.unPublish' : 'artist.dashboard.menu.profilPublish.cta' ))+" ")],1)]]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }