<template>
  <Modal @close-modal="$emit('close-modal')">
    <ArtistProfileEditVideo
      v-if="currentEditedVideo"
      @close-edit="currentEditedVideo = null"
      :video="currentEditedVideo">
    </ArtistProfileEditVideo>

    <form
      v-else
      @submit.prevent="handleSubmit"
      novalidate
      class="artist-profile-upload-videos__wrapper">
      <header class="artist-profile-upload-videos__header">
        <Heading
          :tag="'h2'"
          :level="'h3'"
          class="mr-md">
          {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.title') }}
        </Heading>
        <Paragraph>
          {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.subTitle') }}
        </Paragraph>
      </header>

      <div class="artist-profile-upload-videos__list-wrapper">
        <span
          v-for="(video, index) in videosToDisplay"
          :key="`video-${index}`"
          class="artist-profile-upload-videos__index">
          {{ index + 1 }}.
        </span>
        <SortableList @sort="handleVideosSort">
          <SortableItem
            v-for="(video, index) in videosToDisplay"
            :isHideHandle="!video.URL.startsWith('http') || video.isLoading || (loadingVideoID === video.ID)"
            :key="`video-${index}`">
            <Loader
              v-if="!video.URL.startsWith('http') || video.isLoading || (loadingVideoID === video.ID)"
              :size="'sm'"
              class="mx-auto">
            </Loader>
            <template v-else>
              <video
                :src="video.URL"
                :alt="video.Type"
                class="artist-profile-upload-videos__thumbnail"
                preload="metadata">
              </video>
              <button
                @click="currentEditedVideo = video"
                type="button"
                class="artist-profile-upload-videos__delete-cta">
                {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.trimVideo') }}
              </button>
            </template>
            <button
              @click="handleVideoDelete(video.ID)"
              type="button"
              class="artist-profile-upload-videos__delete-cta">
              {{ $t('common.delete') }}
              <Icon
                :variant="'cross-outline'"
                :size="'xs'"
                class="ml-sm">
              </Icon>
            </button>
          </SortableItem>
        </SortableList>
      </div>

      <Loader
        v-if="isLoading"
        :class="$mediaQueries.isDesktop ? 'mt-lg' : 'mt-auto'">
      </Loader>
      <template v-else>
        <template v-if="videosToDisplay.length < maxVideosUpload">
        <CloudinaryUploadWidget
          @upload-success="handleVideoUpload"
          :uploadOptions="widgetUploadVideoOptionsWithPublicId"
          #default="{ createAndOpenWidget }">
          <Button
            @click="createAndOpenWidget"
            isWhite
            type="button"
            :class="$mediaQueries.isDesktop ? 'my-lg' : 'my-auto'">
            <Icon
              :variant="'plus-outline'"
              :size="'sm'"
              class="mr-sm">
            </Icon>
            {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.addOther') }}
          </Button>
        </CloudinaryUploadWidget>
        <Paragraph
          :size="'sm'"
          class="mb-sm">
          {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.warningVideo') }}
        </Paragraph>
        </template>
        <Paragraph
          v-else
          :size="'sm'"
          class="mb-sm">
          {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.maxVideos') }}
        </Paragraph>
        <Button type="submit">
          {{ $t('common.validate') }}
        </Button>
      </template>
    </form>
  </Modal>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapActions,
}                                       from 'vuex';

import Modal                            from '../../../components/atoms/Modal/a-Modal.vue';
import Heading                          from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                        from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button                           from '../../../components/atoms/Button/a-Button.vue';
import Icon                             from '../../../components/atoms/Icon/a-Icon.vue';
import Loader                           from '../../../components/atoms/Loader/a-Loader.vue';
import SortableList                     from '../../../components/molecules/SortableList/m-SortableList.vue';
import SortableItem                     from '../../../components/molecules/SortableItem/m-SortableItem.vue';
import CloudinaryUploadWidget           from '../../../components/molecules/CloudinaryUploadWidget/m-CloudinaryUploadWidget.vue';
import ArtistProfileEditVideo           from './o-ArtistProfileEditVideo.vue';
import { widgetUploadVideoOptions }     from '../../../constants/cloudinary-upload-options.js';


export default {
  name: 'o-ArtistProfileUploadVideos',
  components: {
    Modal,
    Heading,
    Paragraph,
    Button,
    Icon,
    Loader,
    SortableList,
    SortableItem,
    CloudinaryUploadWidget,
    ArtistProfileEditVideo,
  },
  data: () => ({
    isLoading: false,
    currentEditedVideo: null,
    maxVideosUpload: 2,
    loadingVideoID: 0,
    pendingUploads: [],
    videosRanking: [],
    hasUpdatedRanking: false,
  }),
  computed: {
    ...mapState('User', ['user']),
    ...mapGetters({ userVideos: 'User/MEDIAS_VIDEOS' }),
    videosToDisplay() {
      return [
        ...this.userVideos,
        ...this.pendingUploads,
      ];
    },
    widgetUploadVideoOptionsWithPublicId() {
      return {
        ...widgetUploadVideoOptions,
        publicId: `${this.user.ID}-${Date.parse(new Date())}`,
      };
    },
  },
  watch: {
    userVideos: {
      immediate: true,
      handler(currentVideos, previousVideos) {
        const hasUploadedNewVideo = Boolean(previousVideos?.length && (currentVideos.length > previousVideos.length));
        const hasDeletedVideo = Boolean(previousVideos?.length && (currentVideos.length < previousVideos.length));

        if (hasUploadedNewVideo) this.videosRanking.push(currentVideos[currentVideos.length - 1].ID);
        else if (hasDeletedVideo) {
          const { ID: deleteVideoID } = previousVideos.find(({ ID }) => !currentVideos.some(currentVideo => currentVideo.ID === ID));
          const deletedVideoIndex = this.videosRanking.findIndex(ID => ID === deleteVideoID);

          this.videosRanking.splice(deletedVideoIndex, 1);
        }
        else this.videosRanking = this.userVideos.map(({ ID }) => ID);
      },
    },
  },
  methods: {
    ...mapActions({
      uploadMedia: 'User/UPLOAD_MEDIA',
      deleteMedia: 'User/DELETE_MEDIA',
      updateMediaRanking: 'User/UPDATE_MEDIA_RANKING',
    }),
    async handleSubmit() {
      if (this.hasUpdatedRanking) {
        const params = this.videosRanking.map((ID, index) => ({ id: ID, rank: index }));

        try {
          this.isLoading = true;

          await this.updateMediaRanking(params);
        } finally {
          this.isLoading = false;
        }
      }

      this.$emit('close-modal');
    },
    handleVideosSort({ newIndex, oldIndex }) {
      this.videosRanking.splice(newIndex, 0, this.videosRanking.splice(oldIndex, 1)[0]);
      this.hasUpdatedRanking = true;
    },
    async handleVideoUpload({ url, cropOptions = {}, trimOptions = {} }) {
      const params = {
        input: {
          type: 'VIDEO',
          url,
          options: { ...cropOptions, ...trimOptions },
          rank: this.userVideos?.length ?? 0,
        },
      };

      this.pendingUploads.push({ URL: '', isLoading: true });

      try {
        await this.uploadMedia(params);
      } finally {
        this.pendingUploads = [];
      }
    },
    async handleVideoDelete(mediaID) {
      const params = { id: mediaID, };

      try {
        this.loadingVideoID = mediaID;

        await this.deleteMedia(params);
      } finally {
        this.loadingVideoID = '';
      }
    },
  },
}

</script>

<style lang="scss">

.artist-profile-upload-videos {
  &__wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-md);
  }

  &__list-wrapper {
    display: grid;
    grid-template-columns: 25px 1fr;
    grid-template-rows: 80px;
    grid-auto-rows: 80px;
    grid-row-gap: var(--space-xs);
    align-items: end;
    width: 100%;
    margin-bottom: var(--space-md);

    @media screen and ($desktop) {
      grid-template-columns: 40px minmax(700px, 1fr);
    }
  }

  &__index {
    grid-column: 1 / 2;
    align-self: center;
    width: 40px;
    font-family: var(--font-stack-secondary);
    font-size: var(--text-sm);
    font-weight: var(--font-light);
  }

  &__thumbnail {
    height: 40px;
    width: 60px;
    object-fit: cover;
    border-radius: var(--rounded-xs);
  }

  &__delete-cta {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: var(--text-xs);
    font-family: var(--font-stack-secondary);
    font-weight: var(--font-bold);
    line-height: 2;
    text-decoration: underline;
    text-underline-offset: var(--space-xs);
    color: var(--color-black);
  }
}

</style>
