<template>
  <nav>
    <ol class="app-breadcrumb__levels-list">
      <li
        v-for="(level, index) in breadcrumbData"
        :key="`breadcrumb-level-${index}`"
        :class="{ 'app-breadcrumb__levels-item--active': index === levels.length - 1 }">
        <router-link
          v-if="(index < levels.length - 1) && level.link"
          :to="level.link">
          {{ $t(level.label) }}
        </router-link>
        <span v-else>
          {{ $t(level.label) }}
        </span>
        <Icon
          v-if="index < levels.length - 1"
          :variant="'arrow-right-outline'"
          :size="'xxs'"
          class="app-breadcrumb__levels-item-arrow">
          >
        </Icon>
      </li>
    </ol>
  </nav>
</template>

<script>

import store      from '../../../store/index.js';

import Icon       from '../../atoms/Icon/a-Icon.vue';


export default {
  name: 'm-AppBreadcrumb',
  components: {
    Icon,
  },
  props: {
    levels: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    store,
  }),
  computed: {
    breadcrumbData() {
      return this.$route.meta?.breadcrumb?.map(({ label, link }) => ({
        link: typeof link === 'function' ? link(this) : link,
        label: typeof label === 'function' ? label(this) : label,
      })) ?? [];
    },
  },
}

</script>

<style lang="scss">

.app-breadcrumb {
  &__levels-list {
    display: flex;
    flex-wrap: wrap;
    grid-row-gap: var(--space-xs);
    align-items: center;
    font-family: var(--font-stack-secondary);
    font-size: var(--text-sm);
  }

  &__levels-item {
    &--active {
      font-size: var(--text-xs);
      font-weight: var(--font-bold);
    }
  }

  &__levels-item-arrow {
    display: inline-block;
    margin-left: var(--space-xxs);
    margin-right: var(--space-xs);
    vertical-align: middle;
  }
}

</style>
