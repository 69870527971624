<template>
  <div :class="[wrapperStyle, 'tag__wrapper']">
    <span class="tag__content">
      <!-- @slot Custom tag content -->
      <slot></slot>
    </span>
    <slot name="actions"></slot>
  </div>
</template>

<script>

export default {
  name: 'a-Tag',
  props: {
    color: {
      type: String,
      required: true,
      validator: color => ['neutral', 'light'].includes(color),
    },
    isUppercase: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    wrapperStyle() {
      return {
        'tag__wrapper--uppercase': this.isUppercase,
        [`tag__wrapper--${this.color}`]: true,
      };
    },
  },
}

</script>

<style lang="scss">

.tag {
  &__wrapper {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 30px;
    padding: 0 var(--space-base);
    border-radius: var(--rounded-lg);
    background-color: var(--color-white);
    text-transform: capitalize;

    $colors: (
      'neutral': var(--color-white),
      'light': var(--color-grey-light),
    );

    @each $color-key, $color-value in $colors {
      &--#{$color-key} {
        background-color: $color-value;

        @if $color-key == 'neutral' {
          box-shadow: 0 0 10px 1px hsla(0, 0%, 0%, .075);
        }
      }
    }

    &--uppercase {
      text-transform: uppercase;
      padding: var(--space-xxs) var(--space-xs);
    }
  }

  &__content {
    padding-right: var(--space-xxs);
    font-size: var(--text-xxs);
    font-style: italic;
    line-height: var(--lineheight-xs);
    color: var(--color-grey-dark);

    @include textOverflowEllipsis();
  }
}

</style>
