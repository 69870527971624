<template>
  <section
    class="artist-profile-faq__wrapper"
    id="faq">
    <header class="artist-profile-faq__header">
      <Heading
        :tag="'h2'"
        :level="'h3'"
        :class="{ 'mb-md': isQuestionsListOpen }">
        {{ $t("common.faq") }}
      </Heading>
      <template v-if="!isPublic">
        <Icon
          @click="isQuestionsListOpen = !isQuestionsListOpen"
          :tag="'button'"
          :variant="'edit-outline'"
          :size="'xs'"
          :color="'white'"
          :backdropColor="'black'"
          type="button">
        </Icon>
      </template>
    </header>

    <template v-if="isPublic">
      <dl class="artist-profile-faq__questions-list">
        <div
          v-for="(faq, index) in questionsToDisplay"
          :key="`artist-profile-faq-question-${index}`"
          class="artist-profile-faq__questions-item">
          <dt class="artist-profile-faq__question">
            {{ $t(`faqList.${faq.QuestionTag}`) }}
          </dt>
          <dd class="artist-profile-faq__answer">
            {{ faq.Answer }}
          </dd>
        </div>
      </dl>

      <button
        v-if="artist.FAQ.length > 1 && !isShowAllQuestions"
        @click="isShowAllQuestions = true"
        type="button"
        class="artist-profile-faq__show-all">
        {{ $t('artist.dashboard.menu.faq.seeMore') }}
      </button>
    </template>

    <template v-else-if="!isPublic && isQuestionsListOpen">
      <form
        @submit.prevent="handleSubmit"
        ref="form"
        novalidate>
        <ul>
          <li
            v-for="(question, index) in artistActivityFaqList"
            :key="`artist-profile-faq-questions-edit-${index}`"
            class="artist-profile-faq__questions-edit-item">
            <Paragraph
              class="artist-profile-faq__question-edit"
              :color="'black'"
              isBold>
              {{ $t(`faqList.${question}`) }}
            </Paragraph>
            <FieldInput
              v-model="artistAnswers[question]"
              :id="`edit-faq-answer-${index}`"
              :placeholder="$t('artist.dashboard.menu.faq.answer')"
              :isLiveValidation="false"
              :charactersLimit="255"
              minlength="2"
              type="textarea">
            </FieldInput>
          </li>
        </ul>

        <div class="artist-profile-faq__submit-wrapper">
          <Loader v-if="isLoading"></Loader>
          <template v-else>
            <Button
              type="submit"
              class="mr-sm">
              {{ $t('common.validate') }}
            </Button>
            <Button
              @click="isQuestionsListOpen = false"
              isWhite
              type="button">
              {{ $t('common.cancel') }}
            </Button>
          </template>
        </div>
      </form>
    </template>
  </section>
</template>

<script>

import {
  mapState,
  mapMutations,
  mapActions,
}                       from 'vuex';

import Heading          from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph        from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Icon             from '../../../components/atoms/Icon/a-Icon.vue';
import FieldInput       from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Button           from '../../../components/atoms/Button/a-Button.vue';
import Loader           from '../../../components/atoms/Loader/a-Loader.vue';
import FAQ_LIST         from '../../../constants/faq-list.js';


export default {
  name: 'o-ArtistProfileFAQ',
  components: {
    Heading,
    Paragraph,
    Icon,
    FieldInput,
    Button,
    Loader,
  },
  props: {
    artist: {
      type: Object,
      required: true
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    faqList: FAQ_LIST,
    isLoading: false,
    isQuestionsListOpen: false,
    isShowAllQuestions: false,
    artistAnswers: {},
  }),
  computed: {
    ...mapState('User', ['user']),
    artistActivityFaqList() {
      const artistActivityFaqList = this.user.FAQ.map(({ QuestionTag }) => QuestionTag);

      return artistActivityFaqList;
    },
    answeredArtistQuestions() {
      return this.artist?.FAQ?.filter(({ Answer }) => Answer !== '') ?? [];
    },
    questionsToDisplay() {
      const questions = [];
      const questionsCount = this.isShowAllQuestions
        ? this.answeredArtistQuestions.length
        : Math.min(this.artist.FAQ.length, 1);

      for (let i = 0; i < questionsCount; i++) {
        if (this.answeredArtistQuestions[i]) questions.push(this.answeredArtistQuestions[i]);
      }

      return questions;
    },
  },
  created() {
    if (!this.isPublic) this.setArtistAnswers();
  },
  methods: {
    ...mapMutations({ addErrors: 'ADD_ERRORS' }),
    ...mapActions({ editFaq: 'User/EDIT_FAQ' }),
    setArtistAnswers() {
      this.artistAnswers = this.user.FAQ.reduce((acc, { QuestionTag, Answer }) => ({
        ...acc,
        [QuestionTag]: Answer,
      }), {});
    },
    async handleSubmit() {
      const isValidForm = this.$refs.form.checkValidity();

      if (!isValidForm) return;

      try {
        this.isLoading = true;

        const input = Object.entries(this.artistAnswers)
          .map(([ key, value ]) => ({
            ID: this.user.FAQ.find(({ QuestionTag }) => QuestionTag === key).ID,
            Answer: value,
          }));
        const params = { input };

        await this.editFaq(params);
      } catch (error) {
        this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
      } finally {
        this.isLoading = false;
        this.isQuestionsListOpen = false
      }
    },
  }
}

</script>

<style lang="scss">

.artist-profile-faq {
  &__wrapper {
    grid-column: 1 / 2;
    grid-row: 6 / 7;
    padding-bottom: var(--space-lg);
    border-bottom: 1px solid var(--color-grey-semi);
  }

  &__header {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-row-gap: var(--space-base);
    margin-top: var(--space-xs);
    border-radius: var(--rounded-xs);
  }

  &__questions-list {
    display: flex;
    flex-direction: column;
    font-family: var(--font-stack-secondary);
  }

  &__questions-item {
    padding: var(--space-md);
    background-color: var(--color-grey-light);
    border-radius: var(--rounded-xs);

    &:first-child {
      margin-top: var(--space-md);
    }

    &:not(:last-child) {
      margin-bottom: var(--space-base);
    }
  }

  &__question {
    display: block;
    margin-bottom: var(--space-sm);
    font-size: var(--text-sm);
    font-weight: var(--font-semi);
  }

  &__answer {
    font-size: var(--text-sm);
    font-weight: var(--font-light);
  }

  &__show-all {
    margin: var(--space-md) 0;
    font-size: var(--text-xs);
    font-family: var(--font-stack-secondary);
    font-weight: var(--font-bold);
    text-decoration: underline;
    text-underline-offset: var(--space-xs);
    color: var(--color-black);
  }

  &__questions-edit-item {
    &:not(:last-child) {
      margin-bottom: var(--space-md);
    }

    &:last-child {
      margin-bottom: var(--space-lg);
    }
  }

  &__question-edit {
    line-height: var(--lineheight-lg);
    margin: var(--space-sm) 0;
  }

  &__submit-wrapper {
    display: flex;
    align-items: center;
  }
}

</style>
