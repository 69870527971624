<template>
  <section class="artist-profile-medias__wrapper">
    <section
      v-if="!isPublic && !userPictures.length"
      class="artist-profile-medias__upload-card"
      id="medias-images">
      <header class="artist-profile-medias__upload-card-header">
        <Heading
          :tag="'h4'"
          :level="'h4'"
          :color="'black'"
          class="mb-sm">
          {{ $t('artist.dashboard.menu.header.editPerformance.media.photoMedia.title') }}
        </Heading>
        <Paragraph :color="'black'">
          {{ $t('artist.dashboard.menu.header.editPerformance.media.photoMedia.subTitle') }}
        </Paragraph>
      </header>
      <Button
        v-if="!picturesToDisplay.length"
        @click="isUploadPicturesModalOpen = true"
        isWhite
        hasNoBorder
        type="button">
        <Icon
          :variant="'link-outline'"
          :size="'xs'"
          class="mr-base">
        </Icon>
        {{ $t('artist.dashboard.menu.header.editPerformance.media.photoMedia.addPhotos') }}
      </Button>
      <Loader
        v-else
        :color="'white'">
      </Loader>
    </section>
    <section
      v-else
      :style="{ cursor: isPublic ? 'pointer' : 'initial' }"
      class="artist-profile-medias__pictures-gallery-wrapper"
      id="medias-images">
      <ul
        v-if="picturesToDisplay.length"
        class="artist-profile-medias__pictures-gallery"
        ref="pictures-gallery">
        <li
          v-for="(picture, index) in picturesToDisplay"
          @click="handleLightboxOpen(index)"
          :key="`picture-${index}`"
          class="artist-profile-medias__pictures-gallery-item">
          <img
            :src="picture.URL"
            :alt="picture.Type"
            class="artist-profile-medias__pictures-gallery-picture"/>
        </li>
      </ul>
      <Button
        v-if="!isPublic"
        @click="isUploadPicturesModalOpen = true"
        isWhite
        hasNoBorder
        type="button"
        class="artist-profile-medias__gallery-edit-cta"
        id="medias-images">
        <Icon
          :variant="'photo-outline'"
          :size="'xs'"
          class="mr-base">
        </Icon>
        {{ $t('artist.dashboard.menu.header.editPerformance.media.photoMedia.updatePhotos') }}
      </Button>
    </section>

    <Lightbox
      v-if="isPicturesLightboxOpen"
      @close-lightbox="isPicturesLightboxOpen = false"
      :items="picturesToDisplay"
      :startsAt="lightboxIndex">
      <template #default="{ item }">
        <img
          :src="item.URL"
          alt="Artist picture">
      </template>
    </Lightbox>

    <ArtistProfileUploadPictures
      v-if="isUploadPicturesModalOpen"
      @close-modal="isUploadPicturesModalOpen = false">
    </ArtistProfileUploadPictures>

    <ArtistProfileUploadVideos
      v-if="isUploadVideosModalOpen"
      @close-modal="isUploadVideosModalOpen = false">
    </ArtistProfileUploadVideos>

    <section
      v-if="!isPublic && !videosToDisplay.length"
      class="artist-profile-medias__upload-card"
      id="medias-videos">
      <header class="artist-profile-medias__upload-card-header">
        <Heading
          :tag="'h4'"
          :level="'h4'"
          :color="'black'"
          class="mb-sm">
          {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.title') }}
        </Heading>
        <Paragraph :color="'black'">
          {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.subTitle') }}
        </Paragraph>
      </header>
      <Button
        v-if="!videosToDisplay.length"
        @click="isUploadVideosModalOpen = true"
        hasNoBorder
        isWhite>
        <Icon
          :variant="'link-outline'"
          :size="'xs'"
          class="mr-base">
        </Icon>
        {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.addVideos') }}
      </Button>
    </section>
    <section
      v-if="videosToDisplay.length"
      class="artist-profile-medias__videos-gallery-wrapper"
      id="medias-videos">
      <Icon
        v-if="currentVideoIndex > 0"
        @click="currentVideoIndex--"
        :variant="'chevron-left-outline'"
        :size="'sm'"
        :tag="'button'"
        :backdropColor="'white'"
        type="button"
        class="
          artist-profile-medias__videos-gallery-arrow-cta
          artist-profile-medias__videos-gallery-arrow-cta--left
        ">
      </Icon>
      <VideoMediaCard
        v-if="currentVideo"
        :isPending="!currentVideo.URL.startsWith('http')"
        :isLoading="currentVideo.isLoading"
        :source="currentVideo.URL"
        :key="currentVideo.URL"
        isRoundedBorders>
      </VideoMediaCard>
      <Icon
        v-if="(videosToDisplay.length > 1) && (currentVideoIndex === 0)"
        @click="currentVideoIndex++"
        :variant="'chevron-right-outline'"
        :size="'sm'"
        :tag="'button'"
        :backdropColor="'white'"
        type="button"
        class="
          artist-profile-medias__videos-gallery-arrow-cta
          artist-profile-medias__videos-gallery-arrow-cta--right
        ">
      </Icon>
      <Button
        v-if="!isPublic"
        @click="isUploadVideosModalOpen = true"
        isWhite
        hasNoBorder
        type="button"
        class="artist-profile-medias__gallery-edit-cta"
        id="medias-videos">
        <Icon
          :variant="'photo-outline'"
          :size="'xs'"
          class="mr-base">
        </Icon>
        {{ $t('artist.dashboard.menu.header.editPerformance.media.videoMedia.updateVideos') }}
      </Button>
    </section>
  </section>
</template>

<script>

import {
  mapState,
  mapGetters,
}                                       from 'vuex';

import Loader                           from '../../../components/atoms/Loader/a-Loader.vue';
import Icon                             from '../../../components/atoms/Icon/a-Icon.vue';
import Heading                          from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                        from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button                           from '../../../components/atoms/Button/a-Button.vue';
import Lightbox                         from '../../../components/molecules/Lightbox/m-Lightbox.vue';
import VideoMediaCard                   from '../../../components/molecules/VideoMediaCard/m-VideoMediaCard.vue';
import ArtistProfileUploadPictures      from './o-ArtistProfileUploadPictures.vue';
import ArtistProfileUploadVideos        from './o-ArtistProfileUploadVideos.vue';


export default {
  name: 'o-ArtistProfileMedias',
  components: {
    Loader,
    Icon,
    Heading,
    Paragraph,
    Button,
    Lightbox,
    VideoMediaCard,
    ArtistProfileUploadPictures,
    ArtistProfileUploadVideos,
  },
  props: {
    artist: {
      type: Object,
      required: true
    },
    isPublic: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isPicturesLightboxOpen: false,
    isUploadPicturesModalOpen: false,
    isUploadVideosModalOpen: false,
    maxVideosUpload: 2,
    currentVideoIndex: 0,
    lightboxIndex: 0,
    pendingVideosUploads: [],
    pendingPicturesUploads: [],
  }),
  computed: {
    ...mapState('User', ['user']),
    ...mapGetters({
      userPictures: 'User/MEDIAS_IMAGES',
      userVideos: 'User/MEDIAS_VIDEOS',
      publicArtistVideos: 'SearchArtist/PUBLIC_MEDIAS_VIDEOS',
      publicArtistPictures: 'SearchArtist/PUBLIC_MEDIAS_IMAGES',
    }),
    currentVideo() {
      return this.videosToDisplay[this.currentVideoIndex];
    },
    picturesToDisplay() {
      return this.isPublic
        ? this.publicArtistPictures
        : [...this.userPictures, ...this.pendingPicturesUploads];
    },
    videosToDisplay() {
      return this.isPublic
        ? this.publicArtistVideos
        : [...this.userVideos, ...this.pendingVideosUploads];
    },
  },
  watch: {
    userPictures() {
      this.setGalleryGridRows();
    },
  },
  mounted() {
    this.setGalleryGridRows();
  },
  methods: {
    setGalleryGridRows() {
      this.$refs['pictures-gallery']?.style?.setProperty('--rows', this.userPictures.length - 1);
    },
    handleLightboxOpen(index) {
      if (!this.isPublic) return;

      this.lightboxIndex = index;
      this.isPicturesLightboxOpen = true;
    },
  },
}

</script>

<style lang="scss">

.artist-profile-medias {
  &__wrapper {
    grid-row: 5 / 6;
  }

  &__upload-card {
    display: flex;
    flex-direction: column;
    row-gap: var(--space-md);
    padding: var(--space-lg);
    border-radius: var(--rounded-xs);
    background-color: var(--color-grey-empty);

    &:not(:last-child) {
      margin-bottom: var(--space-md);
    }

    @media screen and ($desktop) {
      flex-direction: row;
      row-gap: 0;
      align-items: center;
    }
  }

  &__upload-card-header {
    flex: 1 1 0;
  }

  &__pictures-gallery-wrapper {
    position: relative;
    margin-bottom: var(--space-md);
  }

  &__pictures-gallery {
    --grid-gap: 3px;
    --rows: 0;

    display: grid;
    height: 400px;
    grid-template-columns: minmax(calc(70% - var(--grid-gap)), 1fr) repeat(auto-fit, minmax(0, 30%));
    grid-template-rows: repeat(var(--rows), calc((400px / var(--rows)) - 2px));
    grid-gap: var(--grid-gap);
  }

  &__pictures-gallery-item {
    &:first-child {
      grid-row: 1 / -1;

      .artist-profile-medias__pictures-gallery-picture {
        border-top-left-radius: var(--rounded-xs);
        border-bottom-left-radius: var(--rounded-xs);
      }

      &:last-child {
        .artist-profile-medias__pictures-gallery-picture {
          border-top-right-radius: var(--rounded-xs);
          border-bottom-right-radius: var(--rounded-xs);
        }
      }
    }

    &:nth-child(2) {
      .artist-profile-medias__pictures-gallery-picture {
        border-top-right-radius: var(--rounded-xs);
      }
    }

    &:last-child {
      &:nth-child(4), &:nth-child(3), &:nth-child(2) {
        .artist-profile-medias__pictures-gallery-picture {
          border-bottom-right-radius: var(--rounded-xs);
        }
      }
    }
  }

  &__pictures-gallery-picture {
    height: 100%;
    max-height: 400px;
    min-width: 100%;
    object-fit: cover;
  }

  &__videos-gallery-wrapper {
    position: relative;
    height: 400px;
    border-radius: var(--rounded-xs);
    background-color: var(--color-black);
  }

  &__videos-gallery-arrow-cta {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    &--left {
      left: var(--space-lg);
    }

    &--right {
      right: var(--space-lg);
    }
  }

  &__gallery-edit-cta {
    position: absolute;
    left: var(--space-lg);
    bottom: var(--space-lg);
  }
}

</style>
