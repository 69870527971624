<template>
  <transition
    name="fade"
    appear>
    <article
      @mousedown.self="$emit('close-modal')"
      class="modal__wrapper">
      <div
        :style="contentStyle"
        class="modal__content">
        <Icon
          v-if="isShowClose"
          @click="$emit('close-modal')"
          :tag="'button'"
          :variant="'cross-outline'"
          :size="'sm'"
          type="button"
          class="modal__close-cta">
        </Icon>
        <slot></slot>
      </div>
    </article>
  </transition>
</template>

<script>

import Icon     from '../Icon/a-Icon.vue';


export default {
  name: 'a-Modal',
  components: { Icon },
  props: {
    width: {
      type: String,
      default: '75%',
    },
    height: {
      type: String,
      default: '100%',
    },
    isShowClose: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    contentStyle() {
      return {
        'max-width': this.$mediaQueries.isDesktop ? this.width : '100%',
        'max-height': `${this.height}`,
      };
    },
  },
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  beforeDestroy() {
    document.body.style.overflow = 'auto';
  },
}

</script>

<style lang="scss">

.modal {
  &__wrapper {
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    background-color: var(--color-grey-semi);

    @media screen and ($desktop) {
      z-index: 10;
      top: var(--space-header-height);
      align-items: center;
      justify-content: center;
    }
  }

  &__content {
    position: relative;
    overflow: auto;
    min-width: 100%;
    max-width: 100%;
    padding: var(--space-lg);
    border-radius: var(--rounded-xs);
    background-color: var(--color-white);

    @media screen and ($desktop) {
      min-width: 60%;
      max-height: 90%;
      max-width: 75%;
      padding: var(--space-xl);
    }
  }

  &__close-cta {
    position: absolute;
    top: calc(5px + var(--space-lg));
    right: var(--space-lg);

    @media screen and ($desktop) {
      top: var(--space-xl);
      right: var(--space-xl);
    }
  }
}

</style>
