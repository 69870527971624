<template>
  <section
    id="artist-pricing"
    class="artist-profile-agenda__wrapper">
    <header>
      <Heading
        :tag="'h2'"
        :level="'h3'"
        class="mb-md">
        {{ $t('artist.dashboard.menu.agendaAndPricing.title') }}
      </Heading>
      <Paragraph
        v-if="!isPublic"
        v-html="$t('artist.dashboard.menu.agendaAndPricing.editPricing.description')"
        class="mb-lg"></Paragraph>
    </header>

    <div
      v-if="!isPublic"
      class="mb-lg">
      <form
        @submit.prevent="handleEditPricing"
        class="artist-profile-agenda__pricings-form"
        ref="form"
        novalidate>
        <Select
          v-model="status"
          :options="statusesOptions"
          :label="`${$t('common.administrativeStatus')}*`"
          id="status-select"
          class="mb-base">
        </Select>
        <Checkbox
          v-if="status && status === 'SELF_EMPLOYED'"
          v-model="isVatTaxable"
          :label="$t('artist.dashboard.menu.agendaAndPricing.editPricing.checkboxTaxes')"
          id="tva"
          class="mb-base">
        </Checkbox>
        <fieldset class="artist-profile-agenda__pricings-list">
          <FieldInput
            v-for="(pricing, index) in pricingOptions"
            v-model="localPricings[pricing.name]"
            @hook:mounted="handleSetTooltipTrigger(pricing)"
            @focus="currentEditedPrice = pricing.name"
            @input="calculateFromPrivatePrice"
            :label="`${pricing.label}*`"
            :key="`pricing-${index}`"
            :id="`pricing-${index}`"
            hasLeftIcons
            type="number"
            step="0.01"
            required>
            <template #label>
              <label
                for="pricing-simulator-asked-fee"
                class="
                  field-input__label
                  artist-profile-agenda__pricing-hint
                ">
                  {{ pricing.label }}*
                <template v-if="pricing.name === 'exception'">
                  <Icon
                    :variant="'question-outline'"
                    :size="'xxs'"
                    :backdropSize="'sm'"
                    :backdropColor="'white'"
                    ref="pricing-hint"
                    class="ml-md">
                  </Icon>
                  <Tooltip :trigger="pricingHintTrigger">
                    <Paragraph>
                      {{ $t('artist.dashboard.menu.agendaAndPricing.editPricing.infoWeekend') }}
                    </Paragraph>
                  </Tooltip>
                </template>
                <Paragraph
                  v-if="status === 'INTERMITTENT'"
                  :size="'sm'"
                  style="font-weight: var(--font-light);"
                  class="artist-profile-agenda__asked-fee-hint">
                  {{ $t('artist.dashboard.menu.pricingSimulator.detailsAskedFeeIntermittent') }}
                </Paragraph>
                <Paragraph
                  v-else-if="status && status !== 'INTERMITTENT'"
                  :size="'sm'"
                  style="font-weight: var(--font-light);"
                  class="artist-profile-agenda__asked-fee-hint">
                  {{ $t('artist.dashboard.menu.pricingSimulator.detailsAskedFeeCompany') }}
                </Paragraph>
              </label>
            </template>
            <template #icons-left>
              <span class="artist-profile-agenda__currency-sign">€</span>
            </template>
          </FieldInput>

          <template v-if="isEditingPricing">
            <Loader
              v-if="isLoading"
              class="mt-md ml-md mr-auto">
            </Loader>
            <div
              v-else
              class="artist-profile-agenda__profile-agenda-pricing-submit-wrapper">
              <Button
                type="submit"
                class="mr-sm">
                {{ $t('common.save') }}
              </Button>
              <Button
                @click="prefillPricings"
                isWhite
                type="button">
                {{ $t('common.cancel') }}
              </Button>
            </div>
          </template>
          <template v-if="currentEditedPrice">
            <hr class="artist-profile-agenda__pricing-separator"/>
            <div :class="{ 'artist-profile-agenda__pricing-simulator-wrapper--exception': currentEditedPrice === 'exception' }">
              <FieldInput
                v-model="publicPrice"
                @input="calculateFromPublicPrice"
                :label="$t('artist.dashboard.menu.pricingSimulator.bookingFee')"
                hasLeftIcons
                id="pricing-simulator-net-fee"
                step="0.01"
                type="number"
                class="mb-sm">
                <template #icons-left>
                  <span class="artist-profile-agenda__currency-sign">€</span>
                </template>
              </FieldInput>
              <FieldInput
                v-model="artistRevenue"
                @input="calculateFromArtistRevenue"
                :label="$t('artist.dashboard.menu.pricingSimulator.artistPayment')"
                hasLeftIcons
                id="pricing-simulator-artist-revenue"
                step="0.01"
                type="number">
                <template #icons-left>
                  <span class="artist-profile-agenda__currency-sign">€</span>
                </template>
              </FieldInput>
            </div>
          </template>
          <Paragraph>
            {{ $t('artist.dashboard.menu.pricingSimulator.details') }}
          </Paragraph>
        </fieldset>

        <Paragraph :size="'sm'">
          *{{ $t('common.mandatoryField') }}
        </Paragraph>
      </form>
    </div>

    <ArtistAgenda
      :isPublic="isPublic"
      id="artist-agenda">
    </ArtistAgenda>
    <hr
      v-if="isPublic"
      class="artist-profile-agenda__separator"
    />
  </section>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions,
}                           from 'vuex';

import Icon                 from '../../../components/atoms/Icon/a-Icon.vue';
import Heading              from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph            from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import FieldInput           from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import Button               from '../../../components/atoms/Button/a-Button.vue';
import Select               from '../../../components/atoms/Select/a-Select.vue';
import Checkbox             from '../../../components/atoms/Checkbox/a-Checkbox.vue';
import Loader               from '../../../components/atoms/Loader/a-Loader.vue';
import Tooltip              from '../../../components/atoms/Tooltip/a-Tooltip.vue';
import ArtistAgenda         from '../../ArtistAgenda/components/o-ArtistAgenda.vue';
import STATUSES_OPTIONS     from '../../../constants/statuses.js';


export default {
  name: 'o-ArtistProfileAgenda',
  components: {
    Icon,
    Heading,
    Paragraph,
    FieldInput,
    Loader,
    Button,
    Select,
    Checkbox,
    Tooltip,
    ArtistAgenda,
  },
  props: {
    isPublic: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    statusesOptions: STATUSES_OPTIONS,
    isLoading: false,
    isVatTaxable: false,
    currentEditedPrice: '',
    localPricings: {
      normal: 0,
      exception: 0,
    },
    status: '',
    privatePrice: 0,
    publicPrice: 0,
    artistRevenue: 0,
    fees: 0.1280,
    buaVat: 0.20,
    payroll: 0.1488,
    employerCharge: 0.4,
    artistNet: 0.4512,
    pricingHintTrigger: {},
  }),
  computed: {
    ...mapState('User', ['user']),
    ...mapGetters({
      hasSetupActivityType: 'User/HAS_SET_ACTIVITY',
      pricings: 'User/PRICINGS',
    }),
    artistVat() {
      return this.isVatTaxable ? 0.20 : 0;
    },
    currentEditedPriceValue() {
      return Number(this.localPricings?.[this.currentEditedPrice] ?? 0);
    },
    isEditingPricing() {
      return !Object
        .entries(this.pricingOptions)
        .every(([ name, { value } ]) => Number(this.localPricings[name]) === value);
    },
    pricingOptions() {
      return {
        normal: {
          name: 'normal',
          value: Number(this.pricings?.find(({ type }) => type === 'normal')?.price) ?? 0,
          label: this.$t(!this.isVatTaxable && (this.status === 'SELF_EMPLOYED')
            ? 'artist.dashboard.menu.agendaAndPricing.editPricing.normalExcludingTaxes'
            : 'artist.dashboard.menu.agendaAndPricing.editPricing.normalTaxes'
          ),
          field: 'PriceNormal',
        },
        exception: {
          name: 'exception',
          value: Number(this.pricings?.find(({ type }) => type === 'exception')?.price) ?? 0,
          label: this.$t(!this.isVatTaxable && (this.status === 'SELF_EMPLOYED')
            ? 'artist.dashboard.menu.agendaAndPricing.editPricing.exceptionalExcludingTaxes'
            : 'artist.dashboard.menu.agendaAndPricing.editPricing.exceptionalTaxes'
          ),
          field: 'PriceException',
        },
      };
    },
  },
  watch: {
    currentEditedPrice(editedPriceType) {
      if (editedPriceType) this.calculateFromPrivatePrice();
    },
    status() {
      this.calculateFromPrivatePrice();
    },
    isVatTaxable() {
      this.calculateFromPrivatePrice();
    },
  },
  created() {
    this.status = this.user?.StatusName ?? '';
    this.isVatTaxable = (this.status === 'INTERMITTENT') || (this.user?.Status?.VATTaxable ?? false);
    this.prefillPricings();
  },
  methods: {
    ...mapMutations({ addErrors: 'ADD_ERRORS' }),
    ...mapActions({ editIdentity: 'User/EDIT_IDENTITY' }),
    handleSetTooltipTrigger(pricing) {
       if (pricing.name === 'exception') this.pricingHintTrigger = this.$refs['pricing-hint'][0];
    },
    prefillPricings() {
      Object
        .values(this.pricingOptions)
        .forEach(({ name, value }) => this.localPricings[name] = value);
      this.calculateFromPrivatePrice();
    },
    async handleEditPricing() {
      const isValidForm = this.$refs.form.checkValidity();

      if (!isValidForm) return;

      const input = Object
        .values(this.pricingOptions)
        .reduce((acc, pricing) => ({ ...acc, [pricing.field]: Number(this.localPricings[pricing.name]) }), {});

      try {
        this.isLoading = true;

        await this.editIdentity({ input });

        this.currentEditedPrice = '';
      } finally {
        this.isLoading = false;
      }
    },
    calculateFromPrivatePrice() {
      this.calculatePublicPrice();
      this.calculateArtistRevenue();
    },
    calculateFromPublicPrice(value) {
      this.calculatePrivatePrice(value);
      this.calculateArtistRevenue();
    },
    calculateFromArtistRevenue(value) {
      if (this.status === 'INTERMITTENT') {
        const commissionOnArtist = (value / (1 + this.buaVat) / (1 + this.fees) * this.fees);
        const rawPayroll = (value / (1 + this.buaVat)) - commissionOnArtist;
        const payroll = (rawPayroll * this.artistNet) / (this.payroll + this.employerCharge + this.artistNet);
        const artistNetRevenue = (rawPayroll * this.payroll) / (this.employerCharge + this.payroll + this.artistNet);
        const artistRevenue = payroll + artistNetRevenue;
        const taxes = (1 + this.buaVat) * (1 + this.fees) * (this.payroll + this.employerCharge + this.artistNet) / (this.payroll + this.artistNet);
        const totalPrice = artistRevenue * taxes * taxes;

        this.localPricings[this.currentEditedPrice] = Number((totalPrice).toFixed(0));
      } else {
        this.localPricings[this.currentEditedPrice] = Number(
          ((value / (1 + this.artistVat)) * ((1 + this.fees) * (1 + this.buaVat))
        ).toFixed(0));
      }
      this.calculatePublicPrice();
    },
    calculatePrivatePrice(value) {
      const buaPrice = (value * (1 + this.artistVat)) / ((1 + this.fees) * (1 + this.buaVat));

      this.localPricings[this.currentEditedPrice] = Number(((buaPrice * 100) / 100).toFixed(0));
    },
    calculatePublicPrice() {
      const buaPrice = (this.currentEditedPriceValue / (1 + this.artistVat)) * ((1 + this.fees) * (1 + this.buaVat));

      this.publicPrice = Number(((buaPrice * 100) / 100).toFixed(0));
    },
    calculateArtistRevenue() {
      if (this.status === 'INTERMITTENT') {
        const commissionOnArtist = (this.currentEditedPriceValue / (1 + this.buaVat) / (1 + this.fees) * this.fees);
        const rawPayroll = (this.currentEditedPriceValue / (1 + this.buaVat)) - commissionOnArtist;
        const payroll = (rawPayroll * this.artistNet) / (this.payroll + this.employerCharge + this.artistNet);
        const artistNetRevenue = (rawPayroll * this.payroll) / (this.employerCharge + this.payroll + this.artistNet);
        const artistRevenue = payroll + artistNetRevenue;

        this.artistRevenue = Number((artistRevenue).toFixed(0));
      } else {
        const buaPrice = ((this.currentEditedPriceValue / (1 + this.artistVat)) / (1 + this.fees) * this.fees);
        const commissionOnArtist = buaPrice * this.buaVat;

        this.artistRevenue = Number((this.currentEditedPriceValue - (buaPrice + commissionOnArtist)).toFixed(0));
      }
    },
  }
}

</script>

<style lang="scss">

.artist-profile-agenda {
  &__wrapper {
    grid-row: 7 / 8;
    display: flex;
    flex-direction: column;
  }

  &__pricings-form {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space-lg);

    @media screen and ($desktop) {
      align-items: flex-start;
    }
  }

  &__pricings-list {
    display: flex;
    flex-direction: column;
    gap: var(--space-base);
    margin-bottom: var(--space-base);

    @media screen and ($desktop) {
      display: grid;
      grid-template-columns: 1fr 1fr;

      p:first-of-type {
        grid-column: span 2;
      }
    }
  }

  &__currency-sign {
    font-size: var(--text-xxs);
    font-style: italic;
    color: var(--color-grey-neutral);
  }

  &__pricing-separator {
    height: 1px;
    border: none;
    background-color: var(--color-grey-semi);
    margin-top: var(--space-base);
    margin-bottom: var(--space-sm);

    @media screen and ($desktop) {
      grid-column: 1 / -1;
    }
  }

  &__pricing-simulator-wrapper {
    grid-column: 1 / 2;

    &--exception {
      grid-column: 2 / 3;
    }
  }

  &__pricing-hint {
    display: grid !important;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    overflow: initial !important;
    white-space: initial !important;
  }

  &__asked-fee-hint {
    grid-row: 2 / 3;
    margin-bottom: var(--space-xx);
  }

  &__profile-agenda-pricing-submit-wrapper {
    display: flex;
    align-items: center;
    margin-top: var(--space-md);

    @media screen and ($desktop) {
      flex: 1 0 100%;
    }
  }

  &__separator {
    height: 1px;
    border: none;
    background-color: var(--color-grey-semi);
    margin-top: var(--space-xl);
  }
}

</style>
