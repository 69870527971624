<template>
  <li class="sortable-item__wrapper">
    <div
      v-if="!isHideHandle"
      class="sortable-item__handle">
      <span
        v-for="(handle, index) in 3"
        :key="`handle-${index}`"
        class="sortable-item__handle-line">
      </span>
    </div>
    <slot></slot>
  </li>
</template>

<script>

export default {
  name: 'm-SortableItem',
  props: {
    isHideHandle: {
      type: Boolean,
      default: false,
    },
  },
}

</script>

<style lang="scss">

.sortable-item {
  &__wrapper {
    display: flex;
    align-items: center;
    height: 80px;
    padding: var(--space-md);
    border-radius: var(--rounded-xs);
    background-color: var(--color-grey-light);

    &:not(:last-child) {
      margin-bottom: var(--space-xs);
    }

    @media screen and ($desktop) {
      padding-left: var(--space-xl);
      padding-right: var(--space-lg);
    }
  }

  &__handle {
    height: 14px;
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: var(--space-md);
    cursor: move;

    @media screen and ($desktop) {
      margin-right: var(--space-xl);
    }
  }

  &__handle-line {
    height: 2px;
    border-radius: var(--rounded-xs);
    background-color: var(--color-grey-dark);
  }
}

</style>
