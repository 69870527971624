<template>
  <transition
    name="fade"
    appear>
    <article
      @mousedown.self="$emit('close-lightbox')"
      class="lightbox__wrapper">
      <Icon
        v-if="currentItemIndex > 0"
        @click="setCurrentItemIndex(-1)"
        :variant="'arrow-left-outline'"
        :size="'sm'"
        :backdropColor="'black'"
        :tag="'button'"
        :color="'white'"
        type="button">
      </Icon>
      <div class="lightbox__content">
        <slot v-bind="{ item: currentItem }"></slot>
      </div>
      <Icon
        v-if="currentItemIndex < (items.length - 1)"
        @click="setCurrentItemIndex(1)"
        :variant="'arrow-right-outline'"
        :size="'sm'"
        :backdropColor="'black'"
        :tag="'button'"
        :color="'white'"
        type="button">
      </Icon>
    </article>
  </transition>
</template>

<script>

import Icon     from '../../atoms/Icon/a-Icon.vue';


export default {
  name: 'm-Lightbox',
  components: { Icon },
  props: {
    items: {
      type: Array,
      required: true,
    },
    startsAt: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    currentItemIndex: 0,
  }),
  computed: {
    currentItem() {
      return this.items[this.currentItemIndex];
    },
  },
  created() {
    this.currentItemIndex = this.startsAt;
  },
  mounted() {
    document.body.style.overflow = 'hidden';
    window.addEventListener('keydown', this.handleKeyDown);
  },
  beforeDestroy() {
    document.body.style.overflow = 'initial';
    window.removeEventListener('keydown', this.handleKeyDown);
  },
  methods: {
    setCurrentItemIndex(newIndex) {
      if ((this.currentItemIndex + newIndex) < 0) return;
      if (this.currentItemIndex + newIndex > (this.items.length - 1)) return;

      this.currentItemIndex = this.currentItemIndex + newIndex;
    },
    handleKeyDown(event) {
      if (event.code === 'ArrowLeft') this.setCurrentItemIndex(-1);
      if (event.code === 'ArrowRight') this.setCurrentItemIndex(1);
    },
  }
}

</script>

<style lang="scss">

.lightbox {
  &__wrapper {
    z-index: 100;
    position: fixed;
    inset: 0;
    display: flex;
    column-gap: var(--space-base);
    align-items: center;
    justify-content: center;
    padding: 0 var(--space-md);
    background-color: hsla(0, 0%, 0%, .95);

    @media screen and ($desktop) {
      padding: 0 var(--space-xl);
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 90%;
    max-width: 90%;
  }
}

</style>
