<template>
  <form
    @submit.prevent="handlePerformanceEdit"
    novalidate
    ref="form"
    class="artist-profile-edit-performance__wrapper">
    <FieldInput
      v-model="description"
      :label="$t('artist.dashboard.menu.header.editPerformance.description.title')"
      :placeholder="$t('artist.dashboard.menu.header.editPerformance.description.emptyInput')"
      :charactersLimit="1000"
      :minlength="minimumDescriptionLength"
      :isLiveValidation="false"
      isAutofocus
      required
      id="description"
      type="textarea"
      class="mb-lg">
    </FieldInput>

    <fieldset>
      <div class="mb-sm">
        <Heading
          :tag="'label'"
          :level="'h5'"
          for="edit-performance-location-range"
          class="mb-sm">
          {{ $t('artist.dashboard.menu.header.editPerformance.locationRange.subTitle') }}
        </Heading>
        <Paragraph
          v-html="$t('artist.dashboard.menu.header.editPerformance.locationRange.description')"
          class="mb-md">
        </Paragraph>
        <RangeSelector
          v-model="selectedLocationIndex"
          :numberOfSteps="locationValues.length"
          :id="'edit-performance-location-range'"
          min="0"
          max="3"
          step="1">
          <template #output>
            {{ outputLocation }}
          </template>
        </RangeSelector>
      </div>
      <div class="mb-sm">
        <Heading
          :tag="'label'"
          :level="'h5'"
          for="edit-performance-location-range"
          class="mb-md">
          {{ $t('artist.dashboard.menu.header.editPerformance.duration.subTitle') }}
        </Heading>
        <RangeSelector
          v-model="selectedPerformanceDurationIndex"
          :numberOfSteps="durationValues.length"
          :id="'edit-performance-duration-range'"
          min="0"
          max="6"
          step="1">
          <template #output>
            {{ outputDuration }}
          </template>
        </RangeSelector>
      </div>
      <div
        v-if="hasActivityField('NumberOfMembers')"
        class="artist-profile-edit-performance__tags-wrapper">
        <FieldInput
          v-model="numberOfMembers"
          :label="$t('artist.dashboard.menu.header.editPerformance.bandMember.title')"
          :placeholder="$t('artist.dashboard.menu.header.editPerformance.tagsArtists.emptyInput')"
          :min="2"
          hasRightIcons
          type="number"
          id="members"
          class="artist-profile-edit-performance__tags-input"
          required>
        </FieldInput>
      </div>
      <div
        v-if="hasActivityField('NumberOfClientSupported')"
        class="artist-profile-edit-performance__tags-wrapper">
        <FieldInput
          v-model="numberOfClientSupported"
          :label="$t('artist.dashboard.menu.header.editPerformance.performanceDetails.participantsNumber', { participantsNumber: numberOfClientSupported || 20 })"
          :placeholder="$t('artist.dashboard.menu.header.editPerformance.performanceDetails.participantsNumber', { participantsNumber: numberOfClientSupported || 20 })"
          :min="1"
          hasRightIcons
          type="number"
          id="clients"
          class="artist-profile-edit-performance__tags-input"
          required>
        </FieldInput>
      </div>
      <div
        v-if="hasActivityField('NumberOfTricks')"
        class="artist-profile-edit-performance__tags-wrapper">
        <FieldInput
          v-model="numberOfTricks"
          :label="$t('artist.dashboard.menu.header.editPerformance.performanceDetails.performanceNumber', { performanceNumber: numberOfTricks || 1 })"
          :placeholder="$t('artist.dashboard.menu.header.editPerformance.performanceDetails.performanceNumber', { performanceNumber: numberOfTricks || 1 })"
          :min="1"
          hasRightIcons
          type="number"
          id="tricks"
          class="artist-profile-edit-performance__tags-input"
          required>
        </FieldInput>
      </div>
      <div
        v-if="hasActivityField('NumberOfCostumes')"
        class="artist-profile-edit-performance__tags-wrapper">
        <FieldInput
          v-model="numberOfCostumes"
          :label="$t('artist.dashboard.menu.header.editPerformance.performanceDetails.costumeNumber', { costumeNumber: numberOfCostumes || 1 })"
          :placeholder="$t('artist.dashboard.menu.header.editPerformance.performanceDetails.costumeNumber', { costumeNumber: numberOfCostumes || 1 })"
          :min="3"
          hasRightIcons
          type="number"
          id="costums"
          class="artist-profile-edit-performance__tags-input"
          required>
        </FieldInput>
      </div>
    </fieldset>

    <fieldset class="artist-profile-edit-performance__extra-infos-wrapper">
      <div
        @keypress.enter.prevent="addTag"
        class="artist-profile-edit-performance__tags-wrapper">
        <FieldInput
          v-model="selectedTag"
          @option-selected="handleTagsSuggestionSelect"
          :label="$t('artist.dashboard.menu.header.editPerformance.tagsArtists.subTitle')"
          :placeholder="$t('artist.dashboard.menu.header.editPerformance.tagsArtists.emptyInput')"
          :disabled="tags.length >= 10"
          :maxlength="50"
          :comboBoxOptions="artistActivityTagsSuggestions"
          :required="tags.length < 2"
          isComboBox
          hasRightIcons
          id="tag"
          class="artist-profile-edit-performance__tags-input">
          <template #label>
            <label
              for="tag"
              class="
                field-input__label
                artist-profile-edit-performance__tags-label
              ">
              {{ $t('artist.dashboard.menu.header.editPerformance.tagsArtists.subTitle') }}
              <Paragraph>
                {{ $t('artist.dashboard.menu.header.editPerformance.tagsArtists.description') }}
              </Paragraph>
            </label>
          </template>
          <template
            v-if="selectedTag"
            #icons-right>
            <Icon
              @click="addTag"
              :variant="'plus-outline'"
              :tag="'button'"
              :size="'xs'"
              isCircled
              type="button">
            </Icon>
          </template>
        </FieldInput>
        <ul class="artist-profile-edit-performance__tags-list">
          <li
            v-for="(tag, index) in tags"
            :key="`artist-tag-${index}`"
            class="artist-profile-edit-performance__tag-item">
            <Tag :color="'neutral'">
              {{ tag }}
              <template #actions>
                <Icon
                  @click="deleteTag(tag)"
                  :variant="'cross-outline'"
                  :tag="'button'"
                  :size="'xxs'"
                  :color="'black'"
                  type="button"
                  class="ml-xxs">
                </Icon>
              </template>
            </Tag>
          </li>
        </ul>
      </div>
      <div class="artist-profile-edit-performance__tags-wrapper">
        <Select
          v-model="selectedLanguage"
          @change="addLanguage"
          :id="'languages'"
          :label="$t('artist.dashboard.menu.header.editPerformance.languages.subTitle')"
          :options="sortedLanguagesOptions"
          :placeholder="$t('artist.dashboard.menu.header.editPerformance.languages.emptyInput')"
          isResetValueAfterSelect
          class="artist-profile-edit-performance__tags-input">
        </Select>
        <ul class="artist-profile-edit-performance__tags-list">
          <li
            v-for="(language, index) in languages"
            :key="`artist-tag-${index}`"
            class="artist-profile-edit-performance__tag-item">
            <Tag :color="'neutral'">
              {{ $t(getLanguageLabel(language)) }}
              <template #actions>
                <Icon
                  @click="deleteLanguage(language)"
                  :variant="'cross-outline'"
                  :tag="'button'"
                  :size="'xxs'"
                  :color="'black'"
                  type="button"
                  class="ml-xxs">
                </Icon>
              </template>
            </Tag>
          </li>
        </ul>
      </div>
      <div class="artist-profile-edit-performance__equipement-wrapper">
        <Heading
          :tag="'span'"
          :level="'h5'"
          isCapitalized
          class="mb-xs">
          {{ $t('common.equipement') }}*
        </Heading>
        <Paragraph v-html="$t('artist.dashboard.menu.header.editPerformance.tagsArtists.equipement.description')"></Paragraph>
        <TextLink
          :urlPath="'/artist/faq#equipement'"
          target="_blank"
          class="mt-xxs mb-lg">
          {{ $t('artist.dashboard.menu.header.editPerformance.tagsArtists.equipement.link') }}
        </TextLink>
        <Checkbox
          v-model="hasCapacityEquipment"
          :label="$t('artist.dashboard.menu.header.editPerformance.tagsArtists.equipement.accept')"
          required
          id="equipement"
          class="mb-lg">
        </Checkbox>
      </div>
      <Paragraph :size="'sm'">
        *{{ $t('common.mandatoryField') }}
      </Paragraph>
    </fieldset>

    <Loader
      v-if="isLoading"
      class="mt-md mr-auto">
    </Loader>
    <div
      v-else
      class="artist-profile-edit-performance__submit-wrapper">
      <Button
        type="submit"
        class="mr-sm">
        {{ $t('common.validate') }}
      </Button>
      <Button
        @click="$emit('success')"
        isWhite
        isTransparentBackground
        type="button">
        {{ $t('common.cancel') }}
      </Button>
    </div>
  </form>
</template>

<script>

import { mapMutations, mapActions }           from 'vuex';

import Heading                                from '../../../components/atoms/Heading/a-Heading.vue';
import Loader                                 from '../../../components/atoms/Loader/a-Loader.vue';
import Icon                                   from '../../../components/atoms/Icon/a-Icon.vue';
import Tag                                    from '../../../components/atoms/Tag/a-Tag.vue';
import Paragraph                              from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button                                 from '../../../components/atoms/Button/a-Button.vue';
import TextLink                               from '../../../components/atoms/TextLink/a-TextLink.vue';
import Checkbox                               from '../../../components/atoms/Checkbox/a-Checkbox.vue';
import Select                                 from '../../../components/atoms/Select/a-Select.vue';
import FieldInput                             from '../../../components/atoms/FieldInput/a-FieldInput.vue';
import RangeSelector                          from '../../../components/molecules/RangeSelector/m-RangeSelector.vue';
import LANGUAGES_OPTIONS                      from '../../../constants/languages.js';
import TAGS_SUGGESTIONS                       from '../../../constants/tagsSuggestions.js';
import { sortTranslationsAlphabetically }     from '../../../utils/i18nUtils';


export default {
  name: 'o-ArtistProfileEditPerformance',
  components: {
    Heading,
    Loader,
    Icon,
    Tag,
    Paragraph,
    Button,
    TextLink,
    Checkbox,
    Select,
    FieldInput,
    RangeSelector,
  },
  props: {
    artist: {
      type: Object,
      required: true
    },
  },
  data: () => ({
    isLoading: false,
    tagsSuggestions: TAGS_SUGGESTIONS,
    languagesOptions: LANGUAGES_OPTIONS,
    description: '',
    locationValues: [25, 50, 100, 150],
    durationValues: [0.5, 1, 2, 3, 4, 5, 6],
    minimumDescriptionLength: 50,
    numberOfMembers: 2,
    numberOfClientSupported: 20,
    numberOfTricks: 1,
    numberOfCostumes: 3,
    selectedLocationIndex: 1,
    selectedPerformanceDurationIndex: 1,
    selectedTag: '',
    selectedLanguage: '',
    languages: [],
    tags: [],
    hasCapacityEquipment: false,
  }),
  computed: {
    outputLocation() {
      return `${this.locationValues[this.selectedLocationIndex]} km`;
    },
    outputDuration() {
      const value = this.durationValues[this.selectedPerformanceDurationIndex];

      return value === 0.5 ? '30 mn' : `${value} h`;
    },
    sortedLanguagesOptions() {
      return [...this.languagesOptions].sort(({ label: labelA }, { label: labelB }) => sortTranslationsAlphabetically(labelA, labelB));
    },
    artistActivityTagsSuggestions() {
      return this.tagsSuggestions?.[this.artist.ActivityInformations.__typename]
        .filter(suggestion => !this.tags.includes(suggestion))
          ?? [];
    },
    canSave() {
      return this.description
        && this.description.length >= this.minimumDescriptionLength
        && this.tags.length >= 2
        && this.hasCapacityEquipment
        && (this.hasActivityField('NumberOfMembers') ? this.numberOfMembers > 0 : true)
        && (this.hasActivityField('NumberOfClientSupported') ? this.numberOfClientSupported > 0 : true)
        && (this.hasActivityField('NumberOfTricks') ? this.numberOfTricks > 0 : true)
        && (this.hasActivityField('NumberOfCostumes') ? this.numberOfCostumes > 0 : true);
    },
  },
  created() {
    this.prefillFields();
  },
  methods: {
    ...mapMutations({ addErrors: 'ADD_ERRORS', }),
    ...mapActions({
      editIdentity: 'User/EDIT_IDENTITY',
      editActivity: 'User/EDIT_ACTIVITY',
    }),
    prefillFields() {
      this.description = this.artist?.Description ?? '';
      this.selectedLocationIndex = (1 + this.locationValues.indexOf(this.artist.LocationRange)) || 2;
      this.selectedPerformanceDurationIndex = (1 + this.durationValues.indexOf(this.artist?.PerformanceDuration)) || 4;
      this.tags = [...this.artist?.Tags ?? []];
      this.hasCapacityEquipment = this.artist?.HasCapacityEquipment ?? false;
      this.languages = [...this.artist?.Languages ?? []];
      this.numberOfMembers = this.artist?.ActivityInformations?.NumberOfMembers ?? 2;
      this.numberOfClientSupported = this.artist?.ActivityInformations?.NumberOfClientSupported ?? 20;
      this.numberOfTricks = this.artist?.ActivityInformations?.NumberOfTricks ?? 1;
      this.numberOfCostumes = this.artist?.ActivityInformations?.NumberOfCostumes ?? 3;
    },
    addTag() {
      const isValidTag = this.selectedTag && this.tags.every(tag => tag.toLowerCase() !== this.selectedTag.toLowerCase());

      if (!isValidTag) return;

      this.tags.push(this.selectedTag);
      this.selectedTag = '';
    },
    deleteTag(tagToDelete) {
      this.tags = this.tags.filter(tag => tag !== tagToDelete);
    },
    handleTagsSuggestionSelect(selectedTagSuggestion) {
      this.selectedTag = selectedTagSuggestion;
      this.addTag();
    },
    addLanguage() {
      const isValidLanguage = this.selectedLanguage && !this.languages.includes(this.selectedLanguage);

      if (!isValidLanguage) return;

      this.languages.push(this.selectedLanguage);
      this.selectedLanguage = '';
    },
    deleteLanguage(languageToDelete) {
      this.languages = this.languages.filter(language => language !== languageToDelete);
    },
    getLanguageLabel(language) {
      return this.languagesOptions.find(({ value }) => value === language)?.label ?? '';
    },
    hasActivityField(field) {
      return this.artist.ActivityInformations[field] !== undefined;
    },
    async handlePerformanceEdit() {
      if (this.selectedTag) this.addTag();

      const isFormValid = this.$refs.form.checkValidity();

      if (!isFormValid) return;

      try {
        this.isLoading = true;

        const { __typename, ...selectedActivityGeneralInformations } = this.artist.ActivityInformations;
        const identityParams = {
          input: {
            PerformanceDuration: this.durationValues[this.selectedPerformanceDurationIndex - 1],
            LocationRange: this.locationValues[this.selectedLocationIndex - 1],
            Description: this.description,
            Tags: this.tags,
            Languages: this.languages,
            HasCapacityEquipment: this.hasCapacityEquipment,
          },
        };
        const activityParams = {
          input: {
            [__typename]: {
              Activity: __typename.toUpperCase(),
              ...selectedActivityGeneralInformations,
              ...(this.hasActivityField('NumberOfMembers') ? { NumberOfMembers: this.numberOfMembers } : {}),
              ...(this.hasActivityField('NumberOfClientSupported') ? { NumberOfClientSupported: this.numberOfClientSupported } : {}),
              ...(this.hasActivityField('NumberOfTricks') ? { NumberOfTricks: this.numberOfTricks } : {}),
              ...(this.hasActivityField('NumberOfCostumes') ? { NumberOfCostumes: this.numberOfCostumes } : {}),
            },
          },
        };

        await this.editIdentity(identityParams);
        await this.editActivity(activityParams);

        this.$emit('success');
      } catch (error) {
        this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
      } finally {
        this.isLoading = false;
      }
    }
  }
}

</script>

<style lang="scss">

.artist-profile-edit-performance {
  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__extra-infos-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space-lg);
  }

  &__tags-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: var(--space-md);

    @media screen and ($desktop) {
      margin-bottom: var(--space-base);
    }
  }

  &__tags-input {
    margin-bottom: var(--space-base);

    @media screen and ($desktop) {
      min-width: 50%;
      max-width: 50%;
    }
  }

  &__tags-label {
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
    margin-bottom: var(--space-sm) !important;
    white-space: initial !important;
    text-overflow: initial !important;
    overflow: initial !important;

    @media screen and ($desktop) {
      width: 200%;
    }
  }

  &__tags-list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__tag-item {
    margin-bottom: var(--space-xs);

    &:not(:last-child) {
      margin-right: var(--space-sm);
    }
  }

  &__equipement-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__submit-wrapper {
    display: flex;
    align-items: center;
    margin-top: var(--space-md);
  }
}

</style>
